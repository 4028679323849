import React, { useRef, useContext } from "react";
import { connect } from "react-redux";
import "./SwitchFarm.css";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { useOnClickOutside } from "../outsideClick";
import { RouterContext } from "../../containers/Contexts";

export function SwitchFarm({ allFarms, currentFarm }) {
    const { t } = useTranslation();
    const switchFarmRef = useRef();
    const navigate = useContext(RouterContext);
    useOnClickOutside(switchFarmRef, navigate.back);

    function ListItem({ farm }) {
        return (
            <li
                className={cn("farm", {
                    selected: farm.id === currentFarm.id,
                })}>
                <a
                    href={
                        farm.id === currentFarm.id ? null : `?farm=${farm.id}`
                    }>
                    <h3
                        className={cn(
                            "problem-count",
                            { error: farm.warningCount > 0 },
                            { stable: farm.warningCount === 0 },
                        )}>
                        {farm.warningCount === 0 ? null : farm.warningCount}
                    </h3>
                    <h3 className="farm-name-location">
                        <strong>{farm.name}</strong> {farm.location}
                    </h3>
                    <h3 className="total-cow">{`${farm.totalCows} ${t(
                        "cows",
                    )}, ${farm.trackedCows} ${t("trackedSwitchFarm")}`}</h3>
                    <h3 className="last-visit">{`${t("lastVisited")}: ${
                        farm.lastVisit === 0 ? t("none") : farm.lastVisit
                    }`}</h3>
                </a>
            </li>
        );
    }
    return (
        <main className="switch-farm" ref={switchFarmRef}>
            <h1 className="header">{t("switchFarm")}</h1>
            <span className="close-button" onClick={navigate.back} />
            <div className="farms">
                <ul className="farm-list">
                    {allFarms.map((farm) => (
                        <ListItem key={farm.id} farm={farm} />
                    ))}
                </ul>
            </div>
        </main>
    );
}
function mapStateToProps({ switchFarm, farms }) {
    return {
        ui: switchFarm.ui,
        allFarms: farms.all,
        currentFarm: farms.current,
    };
}

export default connect(mapStateToProps)(SwitchFarm);
