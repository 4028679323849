import React, { useRef, useContext } from "react";
import { connect } from "react-redux";
import "./NavBar.css";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useGoogleLogout } from "react-google-login";

import Button from "../Button";
import { logout, startTour } from "../../store/actions";
import { useOnClickOutside } from "../outsideClick";
import i18n from "../../i18n";
import { RouterContext } from "../../containers/Contexts";

export function MainMenu({
    currentFarm,
    onLogout,
    startTour,
    disabled,
    metricsAvailable,
    language,
}) {
    const { t } = useTranslation();
    const menuRef = useRef(null);
    const navigate = useContext(RouterContext);
    useOnClickOutside(menuRef, disabled ? () => null : navigate.back);
    const manualLanguage = language === "de" ? "en" : language;

    const { signOut, loaded } = useGoogleLogout({
        clientId:
            "750389719857-bo51argqi7dc6qrr44hr9fm8l8iisg3i.apps.googleusercontent.com",
        onFailure: (error) => console.log("ERROR", error),
        onLogoutSuccess: (v) => console.log("SUCCESS", v),
    });

    function onLogOutLocal() {
        signOut();
        onLogout();
    }

    function ListItem({
        ListClassNames,
        onClicked,
        header,
        headerClassNames,
        buttonClassNames,
        explanation,
        onButtonClicked,
    }) {
        return (
            <li className={ListClassNames} onClick={onClicked}>
                <h1 className={headerClassNames}>{header}</h1>
                {buttonClassNames === "logout" ? (
                    <Button
                        onClicked={onButtonClicked}
                        className="logout"
                        text={t("confirm")}
                        type="action"
                        behaviour="instant"
                    />
                ) : !buttonClassNames && !explanation ? null : (
                    <span
                        className={buttonClassNames}
                        onClick={onButtonClicked}>
                        {explanation}
                    </span>
                )}
            </li>
        );
    }
    function onListItemClicked(action) {
        document.querySelector("button.logout").classList.remove("visible");
        if (action) action();
    }
    function onLogOutConfirm() {
        const buttonLogout = document.querySelector("button.logout");
        if (document.querySelector("button.logout.visible")) {
            buttonLogout.classList.remove("visible");
        } else {
            buttonLogout.classList.add("visible");
        }
    }
    return (
        <nav className={cn("main-menu", { navigating: disabled })}>
            <ul ref={menuRef}>
                <ListItem
                    ListClassNames="farm-name-close"
                    onClicked={() => onListItemClicked()}
                    header={currentFarm.name}
                    headerClassNames="farm-name"
                    buttonClassNames="close-button"
                    onButtonClicked={navigate.back}
                />
                <ListItem
                    ListClassNames="cow-list"
                    onClicked={() =>
                        onListItemClicked(() => navigate.forward("/cows"))
                    }
                    header={t("cowList")}
                    explanation={t("cowListMenuExplanation")}
                />
                <ListItem
                    ListClassNames="sensor-management"
                    onClicked={() =>
                        onListItemClicked(() => navigate.forward("/sensors"))
                    }
                    header={t("deviceManagement")}
                    explanation={t("deviceManagementMenuExplanation")}
                />
                <ListItem
                    ListClassNames="switch-farm"
                    onClicked={() =>
                        onListItemClicked(() => navigate.forward("/switchFarm"))
                    }
                    header={t("switchFarm")}
                    explanation={t("switchFarmMenuExplanation")}
                />
                <ListItem
                    ListClassNames={cn("farm-metrics")}
                    onClicked={() =>
                        onListItemClicked(() =>
                            navigate.forward("/farmMetrics"),
                        )
                    }
                    header={t("farmMetrics")}
                    explanation={t("farmMetricsExplanation")}
                />
                <ListItem
                    ListClassNames={cn("health-report")}
                    onClicked={() =>
                        onListItemClicked(() =>
                            navigate.forward("/healthReport"),
                        )
                    }
                    header={t("feverReport")}
                    explanation={""}
                />
                <ListItem
                    ListClassNames="your-profile"
                    onClicked={() =>
                        onListItemClicked(() => navigate.forward("/profile"))
                    }
                    header={t("yourProfile")}
                    explanation={t("yourProfileMenuExplanation")}
                />
                <ListItem
                    ListClassNames="farm-settings"
                    onClicked={() =>
                        onListItemClicked(() =>
                            navigate.forward("/settings/farm"),
                        )
                    }
                    header={t("farmSettings")}
                />
                <ListItem
                    ListClassNames="notification-settings"
                    onClicked={() =>
                        onListItemClicked(() =>
                            navigate.forward("/settings/notification"),
                        )
                    }
                    header={t("notificationSettings")}
                    explanation={t("notificationSettingsMenuExplanation")}
                />
                <ListItem
                    ListClassNames="dashboard-settings"
                    onClicked={() =>
                        onListItemClicked(() =>
                            navigate.forward("/settings/dashboard"),
                        )
                    }
                    header={t("dashboardSettings")}
                    explanation={t("dashboardSettingsMenuExplanation")}
                />
                <ListItem
                    ListClassNames="guided-tour"
                    onClicked={startTour}
                    header={t("guidedTour")}
                    explanation={t("guidedTourMenuExplanation")}
                />
                <ListItem
                    ListClassNames="help-and-support"
                    onClicked={() =>
                        onListItemClicked(
                            (window.location.href = `${window._env_.WICOW_WEBSITE}/manuals/${manualLanguage}.pdf`),
                        )
                    }
                    header={t("helpAndSupport")}
                    explanation={t("helpAndSupportMenuExplanation")}
                />
                <ListItem
                    ListClassNames="log-out-confirm"
                    onClicked={onLogOutConfirm}
                    header={t("logOut")}
                    buttonClassNames="logout"
                    onButtonClicked={onLogOutLocal}
                />
            </ul>
        </nav>
    );
}

function mapStateToProps({ farms, navigation: { routes } }) {
    return {
        currentFarm: farms.current,
        disabled: routes.length > 2,
        language: i18n.language,
    };
}

const mapDispatchToProps = { onLogout: logout, startTour };

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
