import React, { useContext } from "react";
import { connect } from "react-redux";
import "./NavBar.css";
import cn from "classnames";

import { RouterContext } from "../../containers/Contexts";

import CowListButton from "./CowListButton";

export function NavBar({ classNames, currentFarm, otherFarms, switchFarmUi }) {
    const navigate = useContext(RouterContext);

    function onIconClicked() {
        navigate.forward("/mainMenu");
    }
    return (
        <React.Fragment>
            <header className={cn("navbar", classNames)}>
                <h1>
                    {currentFarm.name}
                    <span className="trademark"> - wiCow </span>
                </h1>
                <CowListButton />
                <i className="burger" onClick={onIconClicked} />
            </header>
        </React.Fragment>
    );
}

function mapStateToProps({ farms, switchFarm }) {
    return {
        switchFarmUi: switchFarm.ui,
        currentFarm: farms.current,
        otherFarms: farms.all.filter((farm) => farm.id !== farms.current.id),
    };
}

export default connect(mapStateToProps)(NavBar);
