import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useDebounce } from "../hooks";
import { useTranslation } from "react-i18next";

export const components = {};
const debounceTime = 500; //ms

export const Tag = ({ props: { value, onRemoveClicked, onSorted, sortOrder } }) => {
    return (
        <tag className={sortOrder} >
            <span onClick={onSorted}>{value}</span>
            <span className="remove" onClick={onRemoveClicked}></span>
        </tag>
    );
};
components.tag = Tag;

export const Logic = ({ props: { value } }) => {
    return (
        <logic>
            { value }
        </logic>
    );
};
components.logic = Logic;

export const InRange = ({
    props: {
        start,
        end,
        following,
        onChanged,
        onClick,
        readOnly = true,
        isFocused = false
    }
}) => {
    const { t } = useTranslation();
    const [virtualEvent, setVirtualEvent] = useState(null);
    const [startLocal, setStartLocal] = useState(start);
    const [endLocal, setEndLocal] = useState(end);
    const debouncedEvent = useDebounce(virtualEvent, debounceTime);
    useEffect(() => {
        if (debouncedEvent) onChanged(virtualEvent);
    }, [debouncedEvent]);

    function onChangedLocal({ target }){
        if (target.classList.contains("start"))
            setStartLocal(parseInt(target.value));
        if (target.classList.contains("end"))
            setEndLocal(parseInt(target.value));
        setVirtualEvent({ target });
    }

    following = t(following, { count: endLocal - startLocal });
    return (
        <parameter>
            <input
                className={cn({ "tag-param-input": true, "start": true, [`digits-${start.length}`]: true })}
                autoFocus={isFocused}
                type="number"
                // polling event asyncroniously is not recommended
                onChange={({ target }) => onChangedLocal({ target })}
                onClick={onClick}
                defaultValue={start}
                readOnly={readOnly}
                onFocus={({ target }) => { if (!readOnly) target.select(); }}
            />
            <span> &minus; </span>
            <input
                className={cn({ "tag-param-input": true, "end": true, [`digits-${end.length}`]: true })}
                type="number"
                onChange={({ target }) => onChangedLocal({ target })}
                onClick={onClick}
                defaultValue={end}
                readOnly={readOnly}
                onFocus={({ target }) => { if (!readOnly) target.select(); }}
            />

            <span className="following"> { following }</span>
        </parameter>
    );
};
components.inRange = InRange;

export const Higher = ({
    props: {
        value,
        following,
        onChanged,
        onClick,
        readOnly = true,
        isFocused
    }
}) => {
    const { t } = useTranslation();
    const [virtualEvent, setVirtualEvent] = useState(null);
    const debouncedEvent = useDebounce(virtualEvent, debounceTime);
    useEffect(() => {
        if (debouncedEvent) onChanged(virtualEvent);
    }, [debouncedEvent]);
    const defaultValueDigits = `digits-${value.length}`;
    following = t(following, { count: parseInt(value) });
    return (
        <parameter>
            <span> &gt; </span>
            <input
                className={cn({ "tag-param-input": true, "value": true, [defaultValueDigits]: true })}
                autoFocus={isFocused}
                type="number"
                onChange={({ target }) => setVirtualEvent({ target })}
                onClick={onClick}
                defaultValue={value}
                readOnly={readOnly}
                onFocus={({ target }) => { if (!readOnly) target.select(); }}
            />
            <span className="following"> { following }</span>
        </parameter>
    );
};
components.higher = Higher;

export const Smaller = ({
    props: {
        value,
        following,
        onChanged,
        onClick,
        readOnly = true,
        isFocused
    }
}) => {
    const { t } = useTranslation();
    const [virtualEvent, setVirtualEvent] = useState(null);
    const debouncedEvent = useDebounce(virtualEvent, debounceTime);
    useEffect(() => {
        if (debouncedEvent) onChanged(virtualEvent);
    }, [debouncedEvent]);
    const defaultValueDigits = `digits-${value.length}`;
    following = t(following, { count: parseInt(value) });
    return (
        <parameter>
            <span> &lt; </span>
            <input
                className={cn({ "tag-param-input": true, "value": true, [defaultValueDigits]: true })}
                autoFocus={isFocused}
                type="number"
                onChange={({ target }) => setVirtualEvent({ target })}
                onClick={onClick}
                defaultValue={value}
                readOnly={readOnly}
                onFocus={({ target }) => { if (!readOnly) target.select(); }}
            />
            <span className="following"> {following}</span>
        </parameter>
    );
};
components.smaller = Smaller;
