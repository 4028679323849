// Events saga
export const START_FETCH_EVENTS_REQUEST = "START_FETCH_EVENTS_REQUEST";
export const COMPLETE_FETCH_EVENTS_REQUEST = "COMPLETE_FETCH_EVENTS_REQUEST";
export const FAIL_FETCH_EVENTS_REQUEST = "FAIL_FETCH_EVENTS_REQUEST";
export const FETCH_OLDER_EVENTS = "FETCH_OLDER_EVENTS";

export const START_FETCH_EVENTS_VIEW_REQUEST =
    "START_FETCH_EVENTS_VIEW_REQUEST";
export const COMPLETE_FETCH_EVENTS_VIEW_REQUEST =
    "COMPLETE_FETCH_EVENTS_VIEW_REQUEST";
export const FAIL_FETCH_EVENTS_VIEW_REQUEST = "FAIL_FETCH_EVENTS_VIEW_REQUEST";

export const FETCH_EVENTS_MANUALLY = "FETCH_EVENTS_MANUALLY";

// Mark read
export const MARK_READ = "MARK_READ";
export const START_MARK_READ_REQUEST = "START_MARK_READ_REQUEST";
export const COMPLETE_MARK_READ_REQUEST = "COMPLETE_MARK_READ_REQUEST";
export const FAIL_MARK_READ_REQUEST = "FAIL_MARK_READ_REQUEST";

// Submit note
export const INPUT_NOTE = "INPUT_NOTE";
export const SUBMIT_NOTE = "SUBMIT_NOTE";
export const START_SUBMIT_NOTE_REQUEST = "START_SUBMIT_NOTE_REQUEST";
export const COMPLETE_SUBMIT_NOTE_REQUEST = "COMPLETE_SUBMIT_NOTE_REQUEST";
export const FAIL_SUBMIT_NOTE_REQUEST = "FAIL_SUBMIT_NOTE_REQUEST";

// login
export const SKIP_LOGIN_REQUEST = "SKIP_LOGIN_REQUEST";
export const START_LOGIN_REQUEST = "START_LOGIN_REQUEST";
export const COMPLETE_LOGIN_REQUEST = "COMPLETE_LOGIN_REQUEST";
export const FAIL_LOGIN_REQUEST = "FAIL_LOGIN_REQUEST";
export const SHOW_LOGIN_FORM = "SHOW_LOGIN_FORM";
export const INPUT_LOGIN_EMAIL = "INPUT_LOGIN_EMAIL";
export const SUBMIT_LOGIN_EMAIL = "SUBMIT_LOGIN_EMAIL";
export const START_LOGIN_LINK_REQUEST = "START_LOGIN_LINK_REQUEST";
export const COMPLETE_LOGIN_LINK_REQUEST = "COMPLETE_LOGIN_LINK_REQUEST";
export const FAIL_LOGIN_LINK_REQUEST = "FAIL_LOGIN_LINK_REQUEST";
export const LOGOUT = "LOGOUT";
export const START_LOGOUT_REQUEST = "START_LOGOUT_REQUEST";
export const COMPLETE_LOGOUT_REQUEST = "COMPLETE_LOGOUT_REQUEST";
export const RETRY_LOGIN_EMAIL = "RETRY_LOGIN_EMAIL";
export const TRY_ANOTHER_LOGIN_EMAIL = "TRY_ANOTHER_LOGIN_EMAIL";
export const FAIL_LOGOUT_REQUEST = "FAIL_LOGOUT_REQUEST";

// App startup
export const START_APP_STARTUP = "START_APP_STARTUP";
export const COMPLETE_APP_STARTUP = "COMPLETE_APP_STARTUP";
export const FAIL_APP_STARTUP = "FAIL_APP_STARTUP";

// Show preview
export const TOGGLE_PREVIEW = "TOGGLE_PREVIEW";

// Temperature
export const START_COW_TEMPERATURE_REQUEST = "START_COW_TEMPERATURE_REQUEST";
export const COMPLETE_COW_TEMPERATURE_REQUEST =
    "COMPLETE_COW_TEMPERATURE_REQUEST";
export const FAIL_COW_TEMPERATURE_REQUEST = "FAIL_COW_TEMPERATURE_REQUEST";

// Pairing list
export const ADD_PAIRING = "ADD_PAIRING";
export const DROP_PAIRING = "DROP_PAIRING";
export const UPDATE_PAIRING_AVAILABILITY = "UPDATE_PAIRING_AVAILABILITY";

export const SHOW_PAIRING_UI = "SHOW_PAIRING_UI";
export const HIDE_PAIRING_UI = "HIDE_PAIRING_UI";
export const SUBMIT_PAIRING_LIST = "SUBMIT_PAIRING_LIST";

export const START_PAIRING_LIST_REQUEST = "START_PAIRING_LIST_REQUEST";
export const COMPLETE_PAIRING_LIST_REQUEST = "COMPLETE_PAIRING_LIST_REQUEST";
export const FAIL_PAIRING_LIST_REQUEST = "FAIL_PAIRING_LIST_REQUEST";

export const SUBMIT_COWS_FROM_COWLIST = "SUBMIT_COWS_FROM_COWLIST";
export const UPDATE_COWS_FROM_COWLIST = "UPDATE_COWS_FROM_COWLIST";
export const RESET_COWS_FROM_COWLIST = "RESET_COWS_FROM_COWLIST";

export const PROCESS_REQUIRED_INFO = "PROCESS_REQUIRED_INFO";
export const START_PROCESS_REQUIRED_INFO = "START_PROCESS_REQUIRED_INFO";
export const COMPLETE_PROCESS_REQUIRED_INFO = "COMPLETE_PROCESS_REQUIRED_INFO";
export const FAIL_PROCESS_REQUIRED_INFO = "FAIL_PROCESS_REQUIRED_INFO";

// Post-startup
export const START_POST_STARTUP = "START_POST_STARTUP";
export const COMPLETE_POST_STARTUP = "COMPLETE_POST_STARTUP";

// Resources
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";

// Sessions
export const FINISH_SESSION = "FINISH_SESSION";
export const START_FINISH_SESSION_REQUEST = "START_FINISH_SESSION_REQUEST";
export const COMPLETE_FINISH_SESSION_REQUEST =
    "COMPLETE_FINISH_SESSION_REQUEST";
export const FAIL_FINISH_SESSION_REQUEST = "FAIL_FINISH_SESSION_REQUEST";

// Cow list
export const ADD_FILTER = "ADD_FILTER";
export const DROP_FILTER = "DROP_FILTER";
export const ADD_QUERY = "ADD_QUERY";
export const DROP_QUERY = "DROP_QUERY";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SELECT_SORT = "SELECT_SORT";

// Confirm calving completed
export const CONFIRM_CALVING = "CONFIRM_CALVING";
export const START_CONFIRM_CALVING_REQUEST = "START_CONFIRM_CALVING_REQUEST";
export const COMPLETE_CONFIRM_CALVING_REQUEST =
    "COMPLETE_CONFIRM_CALVING_REQUEST";
export const FAIL_CONFIRM_CALVING_REQUEST = "FAIL_CONFIRM_CALVING_REQUEST";

// Data
export const START_COW_DATA_REQUEST = "START_COW_DATA_REQUEST";
export const OLD_COW_DATA_REQUEST = "OLD_COW_DATA_REQUEST";
export const COMPLETE_COW_DATA_REQUEST = "COMPLETE_COW_DATA_REQUEST";
export const FAIL_COW_DATA_REQUEST = "FAIL_COW_DATA_REQUEST";
export const CACHED_COW_DATA = "CACHED_COW_DATA";

//Smart Note
export const SET_VARIABLES = "SET_VARIABLES";
export const SET_OPTIONS = "SET_OPTIONS";
export const SET_SMARTNOTE_ABOUT = "SET_SMARTNOTE_ABOUT";
export const SET_FULL_TEXT = "SET_FULL_TEXT";
export const SAVE_VARIABLE_OPTIONS = "SAVE_VARIABLE_OPTIONS";
export const SEND_SMART_NOTE = "SEND_SMART_NOTE";
export const START_SEND_SMART_NOTE = "START_SEND_SMART_NOTE";
export const COMPLETE_SEND_SMART_NOTE = "COMPLETE_SEND_SMART_NOTE";
export const FAIL_SEND_SMART_NOTE = "FAIL_SEND_SMART_NOTE";

// Set Language
export const SET_LANGUAGE_REQUEST = "SET_LANGUAGE_REQUEST";
export const SET_LANGUAGE = "SET_LANGUAGE";

// Config
export const SET_CONFIGS = "SET_CONFIGS";
export const FAIL_SET_CONFIGS = "FAIL_SET_CONFIGS";

// Cow Modal
export const ADD_NEW_COW = "ADD_NEW_COW";
export const ADD_COW = "ADD_COW";
export const START_ADD_COW = "START_ADD_COW";
export const COMPLETE_ADD_COW = "COMPLETE_ADD_COW";
export const FAIL_ADD_COW = "FAIL_ADD_COW";

export const EDIT_COW = "EDIT_COW";
export const SUBMIT_EDIT_COW = "SUBMIT_EDIT_COW";
export const START_SUBMIT_EDIT_COW = "START_SUBMIT_EDIT_COW";
export const COMPLETE_SUBMIT_EDIT_COW = "COMPLETE_SUBMIT_EDIT_COW";
export const FAIL_SUBMIT_EDIT_COW = "FAIL_SUBMIT_EDIT_COW";

export const REMOVE_COW = "REMOVE_COW";
export const START_REMOVE_COW = "START_REMOVE_COW";
export const COMPLETE_REMOVE_COW = "COMPLETE_REMOVE_COW";
export const FAIL_REMOVE_COW = "FAIL_REMOVE_COW";
export const RESET_REMOVE_COW = "RESET_REMOVE_COW";
// Cow Types
export const SET_COW_TYPES = "SET_COW_TYPES";

//Last Email
export const SET_LAST_EMAIL = "SET_LAST_EMAIL";

// repeatre data
export const SET_REPEATER_DATA = "SET_REPEATER_DATA";

// User Preferences

export const SET_ACTIVE_USER = "SET_ACTIVE_USER";
export const UPDATE_INFORMATION = "UPDATE_INFORMATION";
export const START_UPDATE_INFORMATION_REQUEST =
    "START_UPDATE_INFORMATION_REQUEST";
export const COMPLETE_UPDATE_INFORMATION_REQUEST =
    "COMPLETE_UPDATE_INFORMATION_REQUEST";
export const FAIL_UPDATE_INFORMATION_REQUEST =
    "FAIL_UPDATE_INFORMATION_REQUEST";
export const RESET_YOUR_PROFILE = "RESET_YOUR_PROFILE";

export const START_CALL_REQUEST = "START_CALL_REQUEST";
export const COMPLETE_CALL_REQUEST = "COMPLETE_CALL_REQUEST";
export const FAIL_CALL_REQUEST = "FAIL_DO_NOT_CALL_REQUEST";
export const CHANGED_CALL_CONFIG = "CHANGED_CALL_CONFIG";

export const REGISTER_USER = "REGISTER_USER";
export const START_REGISTER_USER = "START_REGISTER_USER";
export const COMPLETE_REGISTER_USER = "COMPLETE_REGISTER_USER";
export const FAIL_REGISTER_USER = "FAIL_REGISTER_USER";

//Notification Settings

export const START_EMAIL_REQUEST = "START_EMAIL_REQUEST";
export const COMPLETE_EMAIL_REQUEST = "COMPLETE_EMAIL_REQUEST";
export const FAIL_EMAIL_REQUEST = "FAIL_EMAIL_REQUEST";
export const CHANGED_EMAIL_CONFIG = "CHANGED_EMAIL_CONFIG";

export const START_NOTIFICATIONS_REQUEST = "START_NOTIFICATIONS_REQUEST";
export const COMPLETE_NOTIFICATIONS_REQUEST = "COMPLETE_NOTIFICATIONS_REQUEST";
export const FAIL_NOTIFICATIONS_REQUEST = "FAIL_NOTIFICATIONS_REQUEST";
export const CHANGED_NOTIFICATIONS_CONFIG = "CHANGED_NOTIFICATIONS_CONFIG";

export const START_VACATION_MODE_REQUEST = "START_VACATION_MODE_REQUEST";
export const COMPLETE_VACATION_MODE_REQUEST = "COMPLETE_VACATION_MODE_REQUEST";
export const FAIL_VACATION_MODE_REQUEST = "FAIL_VACATION_MODE_REQUEST";
export const CHANGED_VACATION_MODE_CONFIG = "CHANGED_VACATION_MODE_CONFIG";

//Farm Settings

export const INVITE_NEW_USER = "INVITE_NEW_USER";
export const START_INVITE_NEW_USER_REQUEST = "START_INVITE_NEW_USER_REQUEST";
export const COMPLETE_INVITE_NEW_USER_REQUEST =
    "COMPLETE_INVITE_NEW_USER_REQUEST";
export const FAIL_INVITE_NEW_USER_REQUEST = "FAIL_INVITE_NEW_USER_REQUEST";
export const RESET_INVITE_NEW_USER = "RESET_INVITE_NEW_USER";

export const CHANGED_FARM_NAME = "CHANGED_FARM_NAME";
export const START_CHANGE_FARM_NAME_REQUEST = "START_CHANGE_FARM_NAME_REQUEST";
export const COMPLETE_CHANGE_FARM_NAME_REQUEST =
    "COMPLETE_CHANGE_FARM_NAME_REQUEST";
export const FAIL_CHANGE_FARM_NAME_REQUEST = "FAIL_CHANGE_FARM_NAME_REQUEST";
export const RESET_FARM_NAME = "RESET_FARM_NAME";

export const REMOVE_USER = "REMOVE_USER";
export const START_REMOVE_USER_REQUEST = "START_REMOVE_USER_REQUEST";
export const COMPLETE_REMOVE_USER_REQUEST = "COMPLETE_REMOVE_USER_REQUEST";
export const FAIL_REMOVE_USER_REQUEST = "FAIL_REMOVE_USER_REQUEST";
export const RESET_REMOVE_USER = "RESET_REMOVE_USER";

export const REDIRECT_CALL = "REDIRECT_CALL";
export const START_REDIRECT_CALL = "START_REDIRECT_CALL";
export const COMPLETE_REDIRECT_CALL = "COMPLETE_REDIRECT_CALL";
export const FAIL_REDIRECT_CALL = "FAIL_REDIRECT_CALL";
export const RESET_REDIRECT_CALL = "RESET_REDIRECT_CALL";

export const START_USER_CALL_CHANGE = "START_USER_CALL_CHANGE";
export const COMPLETE_USER_CALL_CHANGE = "COMPLETE_USER_CALL_CHANGE";
export const FAIL_USER_CALL_CHANGE = "FAIL_USER_CALL_CHANGE";
export const CHANGED_USER_CALL_CONFIG = "CHANGED_USER_CALL_CONFIG";
export const RESET_USER_CALL = "RESET_USER_CALL";

//Switch Farm
export const SET_FARM_WARNING_COUNTS = "SET_FARM_WARNING_COUNTS";

//Dashboard settings
export const SET_SHORTCUT_BUTTON = "SET_SHORTCUT_BUTTON";
export const SET_AUTO_SORTING = "SET_AUTO_SORTING";

// Dialog
export const SET_DIALOG = "SET_DIALOG";
export const CLEAR_DIALOG = "CLEAR_DIALOG";

// Basestation
export const SET_BASESTATION_DATA = "SET_BASESTATION_DATA";

//Navigation
export const NAVIGATE_FORWARD = "NAVIGATE_FORWARD";
export const NAVIGATE_BACK = "NAVIGATE_BACK";
export const FORCE_NAVIGATE_TO = "FORCE_NAVIGATE_TO";

//Tour
export const START_TOUR = "START_TOUR";
export const STOP_TOUR = "STOP_TOUR";
export const FINISH_TOUR = "FINISH_TOUR";

//Snapshots
export const TAKE_SNAPSHOT = "TAKE_SNAPSHOT";
export const RESTORE_SNAPSHOT = "RESTORE_SNAPSHOT";

export const SET_METRIC_RANGE = "SET_METRIC_RANGE";
export const START_SET_METRIC_RANGE = "START_SET_METRIC_RANGE";
export const COMPLETE_SET_METRIC_RANGE = "COMPLETE_SET_METRIC_RANGE";
export const FAIL_SET_METRIC_RANGE = "FAIL_SET_METRIC_RANGE";
export const RESET_SET_METRIC_RANGE = "RESET_SET_METRIC_RANGE";

//Automation
export const TURN_SWITCH = "TURN_SWITCH";
export const START_TURN_SWITCH = "START_TURN_SWITCH";
export const COMPLETE_TURN_SWITCH = "COMPLETE_TURN_SWITCH";
export const FAIL_TURN_SWITCH = "FAIL_TURN_SWITCH";
export const UNKNOWN_TURN_SWITCH = "UNKNOWN_TURN_SWITCH";
export const RESET_TURN_SWITCH = "RESET_TURN_SWITCH";
export const FAIL_CALVING_REPORT = "FAIL_CALVING_REPORT";

export const SET_HEALTH_RANGE = "SET_HEALTH_RANGE";
export const START_HEALTH_REPORT = "START_HEALTH_REPORT";
export const COMPLETE_HEALTH_REPORT = "COMPLETE_HEALTH_REPORT";
export const FAIL_HEALTH_REPORT = "FAIL_HEALTH_REPORT";
export const RESET_HEALTH_REPORT = "RESET_HEALTH_REPORT";

export const TOGGLE_PREVIEW_AUTOMATION = "TOGGLE_PREVIEW_AUTOMATION";
export const START_AUTOMATION_DATA_REQUEST = "START_AUTOMATION_DATA_REQUEST";
export const COMPLETE_AUTOMATION_DATA_REQUEST =
    "COMPLETE_AUTOMATION_DATA_REQUEST";
export const FAIL_AUTOMATION_DATA_REQUEST = "FAIL_AUTOMATION_DATA_REQUEST";
export const CACHED_AUTOMATION_DATA = "CACHED_AUTOMATION_DATA";

export const SEND_NEW_SMART_NOTE = "SEND_NEW_SMART_NOTE";

// Potential Calvings
export const SET_POTENTIAL_LIST = "SET_POTENTIAL_LIST";
export const START_POTENTIAL_LIST = "START_POTENTIAL_LIST";
export const COMPLETE_POTENTIAL_LIST = "COMPLETE_POTENTIAL_LIST";
export const FAIL_POTENTIAL_LIST = "FAIL_POTENTIAL_LIST";
export const SET_POTENTIAL_CALVINGS_LIST = "SET_POTENTIAL_CALVINGS_LIST";
export const START_POTENTIAL_CALVINGS_LIST = "START_POTENTIAL_CALVINGS_LIST";
export const COMPLETE_POTENTIAL_CALVINGS_LIST =
    "COMPLETE_POTENTIAL_CALVINGS_LIST";
export const FAIL_POTENTIAL_CALVINGS_LIST = "FAIL_POTENTIAL_CALVINGS_LIST";
export const SUBMIT_PAIRINGS_FROM_EXTERNAL = "SUBMIT_PAIRINGS_FROM_EXTERNAL";
export const SET_PAIRINGS_FROM_EXTERNAL = "SET_PAIRINGS_FROM_EXTERNAL";
export const RESET_PAIRINGS_FROM_EXTERNAL = "RESET_PAIRINGS_FROM_EXTERNAL";

// Priviliges
export const SET_PRIVILIGES = "SET_PRIVILIGES";
export const FAIL_SET_PRIVILIGES = "FAIL_SET_PRIVILIGES";

// Demo account
export const FINISH_DEMO = "FINISH_DEMO";
export const SHOW_DEMO_MODAL = "SHOW_DEMO_MODAL";
export const HIDE_DEMO_MODAL = "HIDE_DEMO_MODAL";
export const REGISTER_DEMO_ACCOUNT = "REGISTER_DEMO_ACCOUNT";
export const COMPLETE_REGISTER_DEMO_ACCOUNT = "COMPLETE_REGISTER_DEMO_ACCOUNT";
export const FAIL_REGISTER_DEMO_ACCOUNT = "FAIL_REGISTER_DEMO_ACCOUNT";
export const RESET_DEMO_MODAL = "RESET_DEMO_MODAL";

// google login
export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
