import { NAVIGATE_FORWARD, NAVIGATE_BACK, COMPLETE_APP_STARTUP, FORCE_NAVIGATE_TO } from "../actions/actionTypes";
import isMobile from "../../components/isMobile";

const transition = isMobile() ?
    {
        from: {
            ...desktopTransformCss("translate3d(100%, 0, 0)"),
            position: "fixed",
            zIndex: "101",
            width: "100vw",
            height: "100vh",
            top: "0"
        },
        enter: { ...desktopTransformCss("translate3d(0%, 0, 0)") },
        leave: { ...desktopTransformCss("translate3d(100%, 0, 0)") },
        config: { duration: 400 }
    } :
    {
        from: {
            ...desktopTransformCss("translate3d(100%, 0, 0)"),
            position: "absolute",
            right: "0",
            zIndex: "101",
            top: "2.4rem",
        },
        enter: { ...desktopTransformCss("translate3d(0%, 0, 0)") },
        leave: { ...desktopTransformCss("translate3d(100%, 0, 0)") },
        config: { duration: 400 }
    };

const initial = {
    routes: [],
    config: transition
};

export default function(state = initial, action) {
    switch ( action.type ) {
    case NAVIGATE_FORWARD:
        return {
            routes: state.routes.length == 0 ? ["/", action.to] : [ ...state.routes, action.to],
            config: transition
        };
    case NAVIGATE_BACK:
        return {
            routes: state.routes.slice(0, -1),
            config: transition
        };
    case FORCE_NAVIGATE_TO:
        if (action.to == state.routes[state.routes.length-1]) return state;
        // eslint-disable-next-line no-case-declarations
        const i = state.routes.findIndex((el) => el == action.to);
        if (i != -1 && i+1 != state.routes.length ){
            return {
                ...state,
                routes: state.routes.slice(0, -1 * (i - 1))
            };
        }
        return {
            routes: state.routes.length == 0 ? ["/", action.to] : [ ...state.routes, action.to],
            config: transition
        };
    case COMPLETE_APP_STARTUP:
        return { routes: ["/"], config: transition };
    default: return state;
    }
}

function desktopTransformCss(translate) {
    return {
        "MsTransform": translate,
        "WebkitTransform": translate,
        "MozTransform": translate,
        "transform": translate
    };
}