import React, { useEffect } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

export default function CowOptionsMenu({ action, cow, rightClickEvent, setShowOptionsEvent, isRightClick, isCowId }) {
    const { t } = useTranslation();

    useEffect(
        () => {
            init();
        }
    );

    function clickInsideElement( e, className ) {
        const el = e.srcElement || e.target;
        if ( el.classList.contains(className) ) {
            return true;
        } else {
            return false;
        }
    }

    function getPosition(e) {
        let posx = 0;
        let posy = 0;

        if (!e) e = window.event;

        if (e.pageX || e.pageY) {
            posx = e.layerX;
            posy = e.layerY;
        } else if (e.clientX || e.clientY) {
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }

        return {
            x: posx,
            y: posy
        };
    }

    const cowMenuItemClassName = "option";
    const cowMenuActive = "active";

    let clickCoords;
    let clickCoordsX;
    let clickCoordsY;

    let menuWidth;

    function init() {
        contextListener();
        clickListener();
        keyupListener();
        resizeListener();
    }

    function contextListener() {
        if (isRightClick && isCowId) {
            toggleMenuOn();
            positionMenu(rightClickEvent);
        }
    }

    function clickListener() {
        document.addEventListener( "click", function(e) {
            const clickeElIsItem = clickInsideElement( e, cowMenuItemClassName );

            if ( clickeElIsItem ) {
                e.preventDefault();
                toggleMenuOff();
            } else {
                const button = e.which || e.button;
                if ( button === 1 ) {
                    toggleMenuOff();
                }
            }
        });
    }

    function keyupListener() {
        window.onkeyup = function(e) {
            if ( e.keyCode === 27 ) {
                toggleMenuOff();
            }
        };
    }


    function resizeListener() {
        window.onresize = function(e) {
            toggleMenuOff();
        };
    }
    function toggleMenuOn() {
        document.querySelector(".cow-options-menu").classList.add( cowMenuActive );
    }

    function toggleMenuOff() {
        setShowOptionsEvent(null);
        const cowOptions = document.querySelector(".cow-options-menu");
        if (cowOptions) cowOptions.classList.remove( cowMenuActive );

    }

    function positionMenu(e) {
        clickCoords = getPosition(e);
        clickCoordsX = clickCoords.x;
        clickCoordsY = clickCoords.y;
        const listCow = document.querySelector("li.cow");
        const cowOptions = document.querySelector(".cow-options-menu");
        const cowOptionsStyle = cowOptions.style;
        menuWidth = cowOptions.offsetWidth;
        const listWidth = listCow.offsetWidth;

        if ( clickCoordsX < listWidth - menuWidth ) {
            cowOptionsStyle.left = clickCoordsX + "px";
        } else {
            cowOptionsStyle.left = listWidth - menuWidth + "px";
        }
        cowOptionsStyle.top = clickCoordsY + "px";
    }

    return (
        <nav id={cow.id} className="cow-options-menu">
            <ul className="options">
                <li className={cn("option", "edit")} onClick={(event) => action("edit", event, cow)}>{t("edit")}</li>
                <li className={cn("option", "remove")} onClick={(event) => action("remove", event, cow)}>{t("remove")}</li>
            </ul>
        </nav>
    );

}