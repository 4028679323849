import React, { useState } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import DataGraph from "../DataGraph";
import SensorLabel from "../SensorLabel";

import "./ClimateSensPreview.css";

function ClimateSensPreview({ placePairing: { id, deviceId, placeName } }) {
    const [tooltipClickedVisible, setTooltipClickedVisible] = useState(false);
    return (
        <aside className={cn("climate-sens-preview")}>
            <header>
                <h3>{placeName}</h3>
                <h4>
                    <SensorLabel {...{ type: "", deviceId }} />
                </h4>
            </header>
            <DataGraph
                id={id}
                tooltipClickedVisible={tooltipClickedVisible}
                setTooltipClickedVisible={setTooltipClickedVisible}
                useAutomation
            />
        </aside>
    );
}

function mapStateToProps({ automation }, { id }) {
    return { placePairing: automation[id] };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClimateSensPreview);
