import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Session from "./Session";

export default function CowActionMenu({
    action,
    cow,
    rightClickEvent,
    sessions,
    setRightClickEvent,
    setToast,
}) {
    const { t } = useTranslation();

    useEffect(() => {
        init();
    });

    function clickInsideElement(e, className) {
        const el = e.srcElement || e.target;
        if (el.classList.contains(className)) {
            return true;
        } else {
            return false;
        }
    }

    function getPosition(e) {
        let posx = 0;
        let posy = 0;

        if (!e) e = window.event;

        if (e.pageX || e.pageY) {
            posx = e.pageX;
            posy = e.layerY;
        } else if (e.clientX || e.clientY) {
            posx =
                e.clientX +
                document.body.scrollLeft +
                document.documentElement.scrollLeft;
            posy =
                e.clientY +
                document.body.scrollTop +
                document.documentElement.scrollTop;
        }

        return {
            x: posx,
            y: posy,
            pageY: e.pageY,
        };
    }
    const [position, setPosition] = useState("right");
    const p = getCoordinates(position);

    const cowMenuItemClassName = "item";

    let clickCoords;
    let clickCoordsX;
    let clickCoordsY;

    let menuWidth;
    let menuHeight;
    let windowHeight;

    function init() {
        contextListener();
        clickListener();
        keyupListener();
        resizeListener();
    }

    function contextListener() {
        if (rightClickEvent.button == 2) {
            positionMenu(rightClickEvent);
        }
    }

    function clickListener() {
        document.addEventListener("click", function (e) {
            const clickeElIsItem = clickInsideElement(e, cowMenuItemClassName);
            if (clickeElIsItem) {
                e.preventDefault();
                toggleMenuOff();
            }
        });
    }

    function keyupListener() {
        window.onkeyup = function (e) {
            if (e.keyCode === 27) {
                toggleMenuOff();
            }
        };
    }

    function resizeListener() {
        window.onresize = function (e) {
            toggleMenuOff();
        };
    }
    function toggleMenuOff() {
        setRightClickEvent(null);
    }

    function positionMenu(e) {
        clickCoords = getPosition(e);
        clickCoordsX = clickCoords.x;
        clickCoordsY = clickCoords.y;
        const clickPageY = clickCoords.pageY;

        const previewWidth =
            document.querySelector(".cow-preview").offsetWidth + 4;
        const cowMenu = document.querySelector(".cow-menu");
        const cowMenuStyle = cowMenu.style;
        menuWidth = cowMenu.offsetWidth + 4;
        menuHeight = cowMenu.offsetHeight + 4;
        windowHeight = window.innerHeight;
        const dashboardMarginLeft = window
            .getComputedStyle(document.querySelector(".dashboard"))
            .marginLeft.split("px")[0];

        if (clickPageY > windowHeight - menuHeight / 2) {
            setPosition("up");
            cowMenuStyle.top = clickCoordsY - menuHeight + "px";
            if (clickCoordsX > previewWidth - menuWidth / 2) {
                cowMenuStyle.left = previewWidth - menuWidth + "px";
            } else if (clickCoordsX < menuWidth / 2) {
                cowMenuStyle.left = 0 + "px";
            } else {
                cowMenuStyle.left =
                    clickCoordsX - menuWidth / 2 - dashboardMarginLeft + "px";
            }
        } else {
            if (clickCoordsX > previewWidth - menuWidth) {
                setPosition("left");
                cowMenuStyle.left =
                    clickCoordsX - menuWidth - dashboardMarginLeft + "px";
            } else {
                setPosition("right");
                cowMenuStyle.left = clickCoordsX - dashboardMarginLeft + "px";
            }
            cowMenuStyle.top = clickCoordsY - menuHeight / 2 + "px";
        }
    }

    return (
        <nav id={cow.id} className={cn("cow-menu", position)}>
            <div className="indicator">
                <div className="start">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 10 10">
                        <path d={p.start} />
                    </svg>
                </div>
                <div className="middle">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 10 10">
                        <path d={p.middle} />
                    </svg>
                </div>
                <div className="end">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 10 10">
                        <path d={p.end} />
                    </svg>
                </div>
            </div>
            <ul className={cn("items", cow.id)}>
                <li
                    className={cn("item", "edit")}
                    onClick={(event) => action(event, cow)}>
                    {t("edit")}
                </li>
                {sessions.map((session) => (
                    <Session
                        type="list"
                        session={session}
                        key={session.id}
                        setToast={setToast}
                    />
                ))}
            </ul>
        </nav>
    );
}

function getCoordinates(position) {
    const p = { start: "", middle: "", end: "" };
    switch (position) {
        default:
        case "right":
            p.start = "M10 0 L10 10";
            p.middle = "M10 0 L0 5 L10 10";
            p.end = "M10 0 L10 10";
            break;
        case "left":
            p.start = "M0 0 L0 10";
            p.middle = "M0 0 L10 5 L0 10";
            p.end = "M0 0 L0 10";
            break;
        case "up":
            p.start = "M0 0 L10 0";
            p.middle = "M0 0 L5 10 L10 0";
            p.end = "M0 0 L10 0";
            break;
    }
    return p;
}
