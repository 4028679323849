import { take, put, call, spawn } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
    INVITE_NEW_USER,
    START_INVITE_NEW_USER_REQUEST,
    COMPLETE_INVITE_NEW_USER_REQUEST,
    FAIL_INVITE_NEW_USER_REQUEST,
    RESET_INVITE_NEW_USER
} from "../actions/actionTypes";
import { EVENTS } from "../../constants/schema";
import axios from "axios";

export function* inviteNewUserRequest() {

    const { email } = yield take(INVITE_NEW_USER);
    yield put({ type: START_INVITE_NEW_USER_REQUEST });
    try {
        const finishEvent = { event: EVENTS.NEW_USER_ADDED, payload: { email, platform: "" } };
        const { data } = yield call(axios.post, "/v2/events", finishEvent);
        yield put({ type: COMPLETE_INVITE_NEW_USER_REQUEST, data });
        yield call(delay, 2000);
        yield put({ type: RESET_INVITE_NEW_USER });
    } catch (error) {
        yield put({ type: FAIL_INVITE_NEW_USER_REQUEST, error });
        yield call(delay, 2000);
        yield put({ type: RESET_INVITE_NEW_USER });
    } finally {
        yield spawn(inviteNewUserRequest);
    }
}

