import React, { useRef, useContext } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import "./SensorManagement.css";

import { SENSOR, SESSION } from "../../constants/schema";
import { useOnClickOutside } from "../outsideClick";
import { RouterContext } from "../../containers/Contexts";

export function SensorManagement({ sensors }) {
    const { t } = useTranslation();
    const sensorManagementRef = useRef();
    const navigate = useContext(RouterContext);
    useOnClickOutside(sensorManagementRef, navigate.back);
    function last4(deviceId) {
        return deviceId.match(/([0-9A-F]{4})$/)[1];
    }

    function ListItem({ sensor }) {
        return (
            <li className="sensor">
                <span className={cn("device", sensor.type.toLowerCase())} />
                <h3 className="sensor-id-type">
                    <strong>{last4(sensor.deviceId)}</strong> {sensor.type}
                    <span className="session-cow">
                        {sensor.sessionCow
                            ? ` - ${sensor.sessionCow.farmNumber} ${sensor.sessionCow.name}`
                            : null}
                    </span>
                </h3>
                <ul className="tags">
                    {sensor.tags.map((value) => (
                        <li className={value} key={value}>
                            <strong>{`${t(`${value}`)}`}</strong>
                        </li>
                    ))}
                </ul>
            </li>
        );
    }
    return (
        <main className="sensor-management" ref={sensorManagementRef}>
            <h1 className="header">{t("deviceManagement")}</h1>
            <span className="close-button" onClick={navigate.back} />
            <div className="sensors">
                <ul className="sensor-list">
                    {sensors.map((sensor) => (
                        <ListItem key={sensor.id} sensor={sensor} />
                    ))}
                </ul>
            </div>
        </main>
    );
}
function mapStateToProps({ sensorManagement, sessions, sensors, tags, cows }) {
    return {
        ui: sensorManagement.ui,
        sensors: Object.values(sensors)
            .reduce((acc, sensor) => {
                if (!tags.sensor[sensor.id]) return acc;
                if (
                    tags.sensor[sensor.id]
                        .map((t) => t.value)
                        .includes(SENSOR.EXCLUDED)
                )
                    return acc;
                const session = Object.values(sessions).find(
                    (session) =>
                        session.sensor === sensor.id &&
                        tags.session[session.id] &&
                        tags.session[session.id]
                            .map((t) => t.value)
                            .includes(SESSION.ACTIVE),
                );
                let sessionCow = null;
                if (session) sessionCow = cows[session.cow];
                return [
                    ...acc,
                    {
                        ...sensor,
                        tags: tags.sensor[sensor.id].map((t) => t.value),
                        sessionCow,
                    },
                ];
            }, [])
            .sort((a, b) => {
                if (!a.sessionCow) {
                    return 1;
                } else if (!b.sessionCow) {
                    return -1;
                }
            }),
    };
}

export default connect(mapStateToProps)(SensorManagement);
