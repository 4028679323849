import React, { useEffect } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

export function UserMenu({
    user,
    removeUser,
    rightClickEvent,
    setRightclickEvent,
    isRightClick,
    isUserId,
}) {
    const { t } = useTranslation();

    useEffect(() => {
        init();
    });

    function clickInsideElement(e, className) {
        const el = e.srcElement || e.target;

        if (el.classList.contains(className)) {
            return el;
        } else {
            while (el === el.parentNode) {
                if (el.classList && el.classList.contains(className)) {
                    return el;
                }
            }
        }

        return false;
    }

    function getPosition(e) {
        let posx = 0;
        let posy = 0;

        if (!e) e = window.event;

        if (e.pageX || e.pageY) {
            posx = e.layerX;
            posy = e.layerY;
        } else if (e.clientX || e.clientY) {
            posx =
                e.clientX +
                document.body.scrollLeft +
                document.documentElement.scrollLeft;
            posy =
                e.clientY +
                document.body.scrollTop +
                document.documentElement.scrollTop;
        }

        return {
            x: posx,
            y: posy,
        };
    }

    const userMenuItemClassName = "item";
    const userMenuActive = "active";

    let clickCoords;
    let clickCoordsX;
    let clickCoordsY;
    let menuWidth;

    function init() {
        contextListener();
        clickListener();
        keyupListener();
        resizeListener();
    }

    function contextListener() {
        if (isRightClick && isUserId) {
            document.querySelector(".user-menu").classList.add(userMenuActive);
            positionMenu(rightClickEvent);
        }
    }

    function clickListener() {
        document.addEventListener("click", function (e) {
            const clickeElIsLink = clickInsideElement(e, userMenuItemClassName);

            if (clickeElIsLink) {
                e.preventDefault();
                toggleMenuOff();
            } else {
                const button = e.which || e.button;
                if (button === 1) {
                    toggleMenuOff();
                }
            }
        });
    }

    function keyupListener() {
        window.onkeyup = function (e) {
            if (e.keyCode === 27) {
                toggleMenuOff();
            }
        };
    }

    function resizeListener() {
        window.onresize = function (e) {
            toggleMenuOff();
        };
    }

    function toggleMenuOff() {
        setRightclickEvent(null);
        const userOptions = document.querySelector(".user-menu");
        if (userOptions) userOptions.classList.remove(userMenuActive);
    }

    function positionMenu(e) {
        clickCoords = getPosition(e);
        clickCoordsX = clickCoords.x;
        clickCoordsY = clickCoords.y;

        const listWidth = document.querySelector("li.user").offsetWidth;
        const userMenu = document.querySelector(".user-menu");
        const userMenuStyle = userMenu.style;
        menuWidth = userMenu.offsetWidth;

        if (clickCoordsX < listWidth - menuWidth) {
            userMenuStyle.left = clickCoordsX + "px";
        } else {
            userMenuStyle.left = listWidth - menuWidth + "px";
        }
        userMenuStyle.top = clickCoordsY + "px";
    }

    return (
        <nav id={user.id} className="user-menu">
            <ul className="items">
                <li
                    className={cn("item", "removing")}
                    onClick={() => removeUser(user.id)}>
                    {t("remove")}
                </li>
            </ul>
        </nav>
    );
}
