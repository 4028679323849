import React, { useEffect, useState } from "react";
import cn from "classnames";
import "./FarmSettings.css";
import { useTranslation } from "react-i18next";

import Button from "../Button";

import { ListSlider, Overlay, Left } from "./ListSlider";
import { UserMenu } from "./UserMenu";

export default function UserLine({
    user,
    ownerUserId,
    farmAdmin,
    removeUser,
    SliderEnabled,
    onMoved,
    autoClose,
    isUserOwner,
    redirectCall,
    willCalled,
    canSlide,
    callEnabled,
}) {
    const { t } = useTranslation();
    const [rightClicked, setRightClicked] = useState(null);
    useEffect(() => {
        if (farmAdmin && !isUserOwner) {
            document.addEventListener("contextmenu", contextListener);
            document.addEventListener("click", removeOptions);
            return () => {
                document.removeEventListener("contextmenu", contextListener);
                document.removeEventListener("click", removeOptions);
            };
        }
    });

    function removeOptions(e) {
        e.stopPropagation();
        if (!e.target.closest(".user-menu")) {
            setRightClicked(null);
        }
    }

    function contextListener(e) {
        e.preventDefault();
        if (!e.target.closest(".user-menu")) {
            setRightClicked(e);
        }
    }

    const dragRightLimit = -1 / 8;
    const dragLeftLimit = 1 / 8;
    const transitionLeftLimit = 37 / 100;

    const disabledCall = (willCalled || !user.phoneNumber) && callEnabled;
    const disableRemoving = isUserOwner;
    if (SliderEnabled) {
        return (
            <ListSlider
                limitLeft={window.innerWidth * transitionLeftLimit}
                dragLeftBorder={window.innerWidth * dragLeftLimit}
                dragRightBorder={window.innerWidth * dragRightLimit}
                isUserOwner={isUserOwner}
                canSlide={canSlide}>
                <Overlay
                    key="Overlay"
                    onMoved={onMoved}
                    user={user}
                    autoClose={autoClose}>
                    <li className={cn("user", { "will-call": disabledCall })}>
                        <h1
                            className={cn(
                                "name-surname",
                            )}>{`${user.name} ${user.surname}`}</h1>
                        <h3 className="email-phone">
                            {!user.email && !user.phoneNumber
                                ? ""
                                : !user.email
                                ? `${user.phoneNumber}`
                                : !user.phoneNumber
                                ? `${user.email}`
                                : `${user.email} - ${user.phoneNumber}`}
                        </h3>
                        <h3 className="showing-owner-user">
                            {user.id === ownerUserId
                                ? `${t("thisIsYou")}`
                                : null}
                        </h3>
                    </li>
                </Overlay>
                <Left key="Left">
                    <li className={cn("user-options")}>
                        <Button
                            className={cn("removing", {
                                disabled: disableRemoving,
                            })}
                            onClicked={() => removeUser(user.id)}
                            text={t("remove")}
                            type="action"
                            behaviour="instant"
                            disabled={disableRemoving}
                        />
                        <Button
                            className={cn("call-redirect", {
                                disabled: disabledCall,
                            })}
                            onClicked={() => redirectCall(user.id)}
                            text={t("redirect")}
                            type="action"
                            behaviour="instant"
                            disabled={disabledCall}
                        />
                    </li>
                </Left>
            </ListSlider>
        );
    } else {
        return (
            <React.Fragment>
                <li id={user.id} className={cn("user", { admin: farmAdmin })}>
                    <h1 className="name-surname">{`${user.name} ${user.surname}`}</h1>
                    <h3 className="email-phone">
                        {!user.email && !user.phoneNumber
                            ? ""
                            : !user.email
                            ? `${user.phoneNumber}`
                            : !user.phoneNumber
                            ? `${user.email}`
                            : `${user.email} - ${user.phoneNumber}`}
                    </h3>
                    <h3 className="showing-owner-user">
                        {user.id === ownerUserId ? `${t("thisIsYou")}` : null}
                    </h3>
                    {rightClicked && rightClicked.target.id === user.id ? (
                        <UserMenu
                            user={user}
                            removeUser={removeUser}
                            rightClickEvent={rightClicked}
                            setRightclickEvent={setRightClicked}
                            isRightClick={rightClicked.button === 2}
                            isUserId={rightClicked.target.id}
                        />
                    ) : null}
                </li>
            </React.Fragment>
        );
    }
}
