import { put, take, spawn, call } from "redux-saga/effects";
import { delay } from "redux-saga";
import axios from "axios";
import { EVENTS } from "../../constants/schema";
import {
    NAVIGATE_BACK,
    ADD_COW,
    COMPLETE_ADD_COW,
    FAIL_ADD_COW,
    START_ADD_COW,
    SUBMIT_EDIT_COW,
    COMPLETE_SUBMIT_EDIT_COW,
    FAIL_SUBMIT_EDIT_COW,
    START_SUBMIT_EDIT_COW,
    REMOVE_COW,
    START_REMOVE_COW,
    COMPLETE_REMOVE_COW,
    FAIL_REMOVE_COW,
    RESET_REMOVE_COW,
    CLEAR_DIALOG,
} from "../actions/actionTypes";

export function *addCow() {
    const { cowProperties, isPregnant } = yield take(ADD_COW);
    yield put({ type: START_ADD_COW });
    try {
        const props = Object.keys(cowProperties).reduce((acc, key) => {
            if (cowProperties[key] instanceof Date){
                return { ...acc, [key]: cowProperties[key].getTime() / 1000 }; //seconds
            }
            if (key == "breed"){
                return { ...acc, [key]: cowProperties[key].value }; //breedId
            }
            return { ...acc, [key]: cowProperties[key] };
        }, {});
        const newCowAcquiredEvent = { event: EVENTS.NEW_COW_ACQUIRED, payload: { ...props, isPregnant } };
        const { data } = yield call(axios.post, "/v2/events", newCowAcquiredEvent);
        yield put({ type: COMPLETE_ADD_COW, data });
        yield put({ type: NAVIGATE_BACK });
    } catch (error) {
        yield put({ type: FAIL_ADD_COW, error });
    }
    yield spawn(addCow);
}

export function *editCow() {
    const { cowProperties, isPregnant } = yield take(SUBMIT_EDIT_COW);
    yield put({ type: START_SUBMIT_EDIT_COW });
    try {
        const props = Object.keys(cowProperties).reduce((acc, key) => {
            if (cowProperties[key] instanceof Date){
                return { ...acc, [key]: cowProperties[key].getTime() };
            }
            if (key == "breed"){
                return { ...acc, [key]: cowProperties[key].value }; //breedId
            }
            if (key == "id"){
                return { ...acc, cow: cowProperties[key] }; //cowId
            }
            return { ...acc, [key]: cowProperties[key] };
        }, {});
        const cowEditedEvent = { event: EVENTS.COW_EDITED, payload: { ...props, isPregnant } };
        const { data } = yield call(axios.post, "/v2/events", cowEditedEvent);
        yield put({ type: COMPLETE_SUBMIT_EDIT_COW, data });
        yield put({ type: NAVIGATE_BACK });
    } catch (error) {
        yield put({ type: FAIL_SUBMIT_EDIT_COW, error });
    }
    yield spawn(editCow);
}

export function *removeCow() {
    const { cowId, userId } = yield take(REMOVE_COW);
    yield put({ type: START_REMOVE_COW });
    try {
        const cowDeletedEvent = { event: EVENTS.COW_DIED, payload: { cow: cowId, user: userId } };
        const { data } = yield call(axios.post, "/v2/events", cowDeletedEvent);
        yield put({ type: COMPLETE_REMOVE_COW, data });
        yield put({ type: CLEAR_DIALOG });
        yield call(delay, 2000);
        yield put({ type: RESET_REMOVE_COW });
    } catch (error) {
        yield put({ type: FAIL_REMOVE_COW, error });
    } finally {
        yield spawn(removeCow);
    }
}