import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import CowName from "../CowName";
import "./PairingListPreview.css";
import Time from "../Time";
import { SESSION } from "../../constants/schema";
import SensorLabel from "../SensorLabel";
import { useMobile } from "../isMobile";

function PairingListPreview({ sessions, id, user, time, tags }) {
    const { t } = useTranslation();
    const { isMobileDevice } = useMobile();
    function downloadPDF(id) {
        return () => window.open(`${window._env_.API_HOST}/pairing/${id}.pdf`);
    }

    return (
        <aside className="pairing-list-preview" open>
            <header>
                <h1 className="list-name">
                    {t("pairingList")} #<span className="list-id">{id}</span>
                </h1>
                <h3 className="time-user">
                    <span className="time">
                        <Time
                            time={time}
                            options={{
                                day: "numeric",
                                month: "short",
                                hour: "2-digit",
                                minute: "2-digit",
                            }}
                        />
                    </span>
                    by <span className="user">#{user.name}</span>
                </h3>
                {!isMobileDevice ? (
                    <h2 className="download-pdf" onClick={downloadPDF(id)}>
                        <span>{t("downloadPrintPDFChecklist")}</span>
                    </h2>
                ) : null}
            </header>
            <main>
                <ul className="pairing-list">
                    {sessions.map((session) => {
                        const isSessionActive = tags.session[session.id]
                            .map((i) => i.value)
                            .includes(SESSION.ACTIVE);
                        if (tags.session[session.id][0])
                            return (
                                <li
                                    key={session.id}
                                    className={cn({ active: isSessionActive })}>
                                    #<strong>{session.cow.farmNumber}</strong>
                                    <CowName>{session.cow.name}</CowName> -
                                    <SensorLabel {...session.sensor} />
                                </li>
                            );
                    })}
                </ul>
            </main>
        </aside>
    );
}
function mapStateToProps({
    users,
    cows,
    sessions,
    tags,
    preview,
    pairingLists,
    sensors,
}) {
    const finishableCowSessions = Object.values(sessions)
        .filter(({ pairingList }) => pairingList === preview.id)
        .reduce((acc, s) => {
            return [
                ...acc,
                { ...s, cow: cows[s.cow], sensor: sensors[s.sensor] },
            ];
        }, []);

    const findingSessionsCreator = pairingLists[preview.id].user;
    const findingTimeCreatedList = pairingLists[preview.id].timestamp;
    return {
        tags: tags,
        sessions: finishableCowSessions,
        id: preview.id,
        user: users[findingSessionsCreator],
        time: findingTimeCreatedList,
    };
}

export default connect(mapStateToProps)(PairingListPreview);
