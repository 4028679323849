import { take, put, spawn, call, fork, takeEvery } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
    fetchEvents,
    login,
    logout,
    confirmCalvingRequest,
    getMetrics,
    getHealthReport,
    googleLogin,
} from "./";
import {
    START_APP_STARTUP,
    COMPLETE_APP_STARTUP,
    FAIL_APP_STARTUP,
    COMPLETE_FETCH_EVENTS_REQUEST,
    FAIL_FETCH_EVENTS_REQUEST,
    COMPLETE_LOGIN_REQUEST,
    SKIP_LOGIN_REQUEST,
    FAIL_LOGIN_REQUEST,
    SET_CONFIGS,
    FAIL_SET_CONFIGS,
    SET_ACTIVE_USER,
    SET_FARM_WARNING_COUNTS,
    SET_SHORTCUT_BUTTON,
    SET_AUTO_SORTING,
    SET_PRIVILIGES,
    FAIL_SET_PRIVILIGES,
} from "../actions/actionTypes";

import axios from "axios";

export function* startUp() {
    yield put({ type: START_APP_STARTUP });
    yield spawn(login);
    yield spawn(googleLogin);

    const loginResolution = yield take([
        COMPLETE_LOGIN_REQUEST,
        SKIP_LOGIN_REQUEST,
        FAIL_LOGIN_REQUEST,
    ]);
    if (loginResolution.type === FAIL_LOGIN_REQUEST) {
        return yield put({ ...loginResolution, type: FAIL_APP_STARTUP });
    }
    yield setDefaultAxiosHeaders();

    const farms = yield getFarms();
    const farm = initialFarm(farms);

    document.title = `${farm.name} 🐄 wiCow`;
    axios.defaults.headers["X-Wicow-FarmID"] = farm.id;

    const ownerUserId = JSON.parse(localStorage.getItem("ownerUserId"));
    if (ownerUserId) {
        yield put({
            type: SET_ACTIVE_USER,
            id: ownerUserId,
        });
    } else {
        while (true) {
            try {
                const response = yield call(axios.get, "/users/ownerUserId");
                yield put({
                    type: SET_ACTIVE_USER,
                    id: response.data,
                });
                break;
            } catch (error) {
                yield call(delay, 250);
            }
        }
    }
    const shortcutButton = yield localStorage.getItem("shortcutButton");
    yield put({
        type: SET_SHORTCUT_BUTTON,
        button: shortcutButton ? shortcutButton : "cowList",
    });
    const autoSortingBy = yield localStorage.getItem("autoSortingBy");
    yield put({
        type: SET_AUTO_SORTING,
        button: autoSortingBy ? autoSortingBy : "priority",
    });

    let configs = [];
    try {
        const response = yield call(axios.get, "/users/config");
        configs = response.data;
        yield put({
            type: SET_CONFIGS,
            configs: configs,
        });
    } catch (error) {
        yield put({ type: FAIL_SET_CONFIGS });
    }
    yield fork(getPriviliges);

    if (!configs.find((c) => c.name == "LANGUAGE")) {
        try {
            let defaultLang = yield localStorage.getItem("language");
            if (!defaultLang)
                defaultLang = (
                    navigator.language || navigator.userLanguage
                ).split("-")[0];
            const initLanguageConfig = [
                {
                    configTypesId: "1",
                    name: "LANGUAGE",
                    full_name: "Language",
                    value: defaultLang,
                },
            ];
            yield call(axios.put, "/users/config", initLanguageConfig);
            yield put({
                type: SET_CONFIGS,
                configs: initLanguageConfig,
            });
        } catch (error) {
            console.warn("Could not send language config");
        }
    }

    yield spawn(logout);
    yield spawn(fetchEvents);
    yield spawn(confirmCalvingRequest);
    yield spawn(getFarmWarnings, farms);
    yield spawn(getMetrics);
    yield spawn(getHealthReport);

    const fetchEventsResolution = yield take([
        COMPLETE_FETCH_EVENTS_REQUEST,
        FAIL_FETCH_EVENTS_REQUEST,
    ]);

    if (fetchEventsResolution.type === FAIL_FETCH_EVENTS_REQUEST) {
        return yield put({ ...fetchEventsResolution, type: FAIL_APP_STARTUP });
    }

    yield put({ type: COMPLETE_APP_STARTUP, farms, farm });
    yield localStorage.setItem("lastFarmId", farm.id);
}

function* getPriviliges() {
    try {
        const response = yield call(axios.get, "/users/priviliges");
        yield put({
            type: SET_PRIVILIGES,
            priviliges: response.data,
        });
    } catch (error) {
        yield put({ type: FAIL_SET_PRIVILIGES });
    }
}

function* getFarms() {
    const { data } = yield call(axios.get, "/users/farms");
    return data;
}

function* getFarmWarnings(farms) {
    yield take(COMPLETE_APP_STARTUP);
    const { data } = yield call(axios.get, "/farms/warnings");
    yield put({
        type: SET_FARM_WARNING_COUNTS,
        farmWarnings: data,
    });
    if (farms.length > 1) {
        yield spawn(getFarmWarningsPeriodic);
    }
}

function* getFarmWarningsPeriodic() {
    yield call(delay, 60000); // 1 min
    const { data } = yield call(axios.get, "/farms/warnings");
    yield put({
        type: SET_FARM_WARNING_COUNTS,
        farmWarnings: data,
    });
    yield spawn(getFarmWarningsPeriodic);
}

function initialFarm(farms) {
    const lastFarmId = localStorage.getItem("lastFarmId");
    const currentURL = new URL(window.location);
    const paramsFarm = currentURL.searchParams.get("farm");
    const farm =
        farms.find((f) => f.id === paramsFarm) ||
        farms.find((f) => f.id == lastFarmId) ||
        farms[0];
    return farm;
}

function setDefaultAxiosHeaders() {
    const longTermToken = localStorage.getItem("longTermToken");
    axios.defaults.headers.Authorization = `Bearer ${longTermToken}`;
}
