import axios from "axios";

const apiByWindowLocation = {
    "demo.wicows.com": "https://api.wicows.com",
    "test.demo.wicows.com": "https://test.api.wicows.com",
}[window.location.hostname];

const apiOverride = window.localStorage.getItem("apiOverride");

const defaultApi = window._env_.API_HOST;

axios.defaults.baseURL = apiOverride || apiByWindowLocation || defaultApi;
