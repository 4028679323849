import { take, call, put, spawn, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import axios from "axios";

import {
    SET_POTENTIAL_LIST,
    START_POTENTIAL_LIST,
    COMPLETE_POTENTIAL_LIST,
    FAIL_POTENTIAL_LIST,
    SUBMIT_PAIRINGS_FROM_EXTERNAL,
    SET_PAIRINGS_FROM_EXTERNAL,
    NAVIGATE_BACK,
    SHOW_PAIRING_UI,
} from "../actions/actionTypes";
import { SENSOR } from "../../constants";

export function* potentialList() {
    const { eventId, listType } = yield take(SET_POTENTIAL_LIST);
    const [tags, sensors] = yield select(({ tags, sensors }) => [
        tags,
        sensors,
    ]);
    const avSensors = Object.keys(tags.sensor).filter((k) =>
        tags.sensor[k].find(
            (t) => t.value === SENSOR.IDLE && sensors[k].type === "Tsens",
        ),
    );
    yield put({ type: START_POTENTIAL_LIST });
    try {
        const response = yield call(
            axios.get,
            `/pairing/potential/${listType}/${eventId}`,
        );
        yield put({
            type: COMPLETE_POTENTIAL_LIST,
            ...response.data,
            id: eventId,
            avSensors,
            tags,
        });
    } catch (error) {
        yield put({ type: FAIL_POTENTIAL_LIST });
    }
    yield spawn(potentialList);
}

export function* submitPairingsFromExternal() {
    const { list } = yield take(SUBMIT_PAIRINGS_FROM_EXTERNAL);
    yield put({ type: SET_PAIRINGS_FROM_EXTERNAL, list });
    yield put({ type: NAVIGATE_BACK });
    yield call(delay, 100);
    yield put({ type: SHOW_PAIRING_UI });
    yield spawn(submitPairingsFromExternal);
}
