import { TOGGLE_PREVIEW, TOGGLE_PREVIEW_AUTOMATION } from "../actions/actionTypes";

export default function (state = null, action) {
    switch (action.type) {
    case TOGGLE_PREVIEW:
    case TOGGLE_PREVIEW_AUTOMATION:
        if ( state && state.kind === action.kind && state.id === action.id ) { return null; }
        return { kind: action.kind, id: action.id };
    default: return state;
    }
}
