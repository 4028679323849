import React from "react";
import "./ActivationLoader.css";
import cn from "classnames";

const ActivationLoader = ({ className }) => {
    return (
        <article className={cn("lds-ellipsis", className)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </article>
    );
};

export default ActivationLoader;
