import React, { useRef, useContext } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import {
    callConfig,
    emailConfig,
    notificationsConfig,
    vacationModeConfig,
} from "../../store/actions";
import "./NotificationSettings.css";
import { useOnClickOutside } from "../outsideClick";
import { USER } from "../../constants/schema";
import { RouterContext } from "../../containers/Contexts";

export function NotificationSettings({
    calvingOverdueCall,
    calvingOverdueNotifications,
    calvingOverdueEmail,
    feverHypothermiaCall,
    feverHypothermiaNotifications,
    feverHypothermiaEmail,
    imminentCalvingCall,
    imminentCalvingNotifications,
    imminentCalvingEmail,
    calvingDueIn24HoursCall,
    calvingDueIn24HoursNotifications,
    calvingDueIn24HoursEmail,
    callConfig,
    emailConfig,
    notificationsConfig,
    vacationModeConfig,
    vacationMode,
    calvingOverdueCallUi,
    calvingOverdueNotificationsUi,
    calvingOverdueEmailUi,
    feverHypothermiaCallUi,
    feverHypothermiaNotificationsUi,
    feverHypothermiaEmailUi,
    imminentCalvingCallUi,
    imminentCalvingNotificationsUi,
    imminentCalvingEmailUi,
    calvingDueIn24HoursCallUi,
    calvingDueIn24HoursNotificationsUi,
    calvingDueIn24HoursEmailUi,
    callPriviligesEnabled,
    willCalledForCustomer,
    ownerUser,
}) {
    const { t } = useTranslation();
    const notificationSettingsRef = useRef();
    const navigate = useContext(RouterContext);
    useOnClickOutside(notificationSettingsRef, navigate.back);
    return (
        <main className="notification-settings" ref={notificationSettingsRef}>
            <h1 className="header">{t("notificationSettings")}</h1>
            <span className="close-button" onClick={navigate.back} />
            <div className="explanation">
                <span className="main-explanation">
                    {t("notificationSettingsMainExplanation")}
                </span>
            </div>
            <div className="vacation-mode">
                <h1 className="vacation-header">{t("vacationMode")}</h1>
                <Button
                    onClicked={vacationModeConfig}
                    className={cn("vacation-button", {
                        deactived: !vacationMode,
                    })}
                    text={t("vacation")}
                    type="action"
                    behaviour="instant"
                />
                <span className="vacation-mode-explanation">
                    {t("vacationModeExplanation")}
                </span>
            </div>
            <div className="calving-due">
                <h1 className="calving-due-header">
                    {t("calvingDueIn24Hours")}
                </h1>
                <span className="buttons">
                    <Button
                        onClicked={() =>
                            callConfig(
                                "calvingDueIn24Hours",
                                !calvingDueIn24HoursCall,
                                calvingDueIn24HoursNotifications,
                                calvingDueIn24HoursEmail,
                            )
                        }
                        className={cn(
                            "call",
                            { deactived: !calvingDueIn24HoursCall },
                            {
                                disabled:
                                    vacationMode ||
                                    (ownerUser.apiUserTypesId === 3 &&
                                        !callPriviligesEnabled) ||
                                    (!willCalledForCustomer &&
                                        ownerUser.apiUserTypesId === 3),
                            },
                            calvingDueIn24HoursCallUi,
                        )}
                        text={t("call")}
                        type="action"
                        behaviour="instant"
                        disabled={
                            vacationMode ||
                            (ownerUser.apiUserTypesId === 3 &&
                                !callPriviligesEnabled) ||
                            (!willCalledForCustomer &&
                                ownerUser.apiUserTypesId === 3)
                        }
                    />
                    <Button
                        onClicked={() =>
                            notificationsConfig(
                                "calvingDueIn24Hours",
                                calvingDueIn24HoursCall,
                                !calvingDueIn24HoursNotifications,
                                calvingDueIn24HoursEmail,
                            )
                        }
                        className={cn(
                            "notifications",
                            { deactived: !calvingDueIn24HoursNotifications },
                            { disabled: vacationMode },
                            calvingDueIn24HoursNotificationsUi,
                        )}
                        text={t("notifications")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode}
                    />
                    <Button
                        onClicked={() =>
                            emailConfig(
                                "calvingDueIn24Hours",
                                calvingDueIn24HoursCall,
                                calvingDueIn24HoursNotifications,
                                !calvingDueIn24HoursEmail,
                            )
                        }
                        className={cn(
                            "email",
                            { deactived: !calvingDueIn24HoursEmail },
                            { disabled: vacationMode },
                            calvingDueIn24HoursEmailUi,
                        )}
                        text={t("email")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode}
                    />
                </span>
                <span className="calving-due-explanation">
                    {t("calvingDueExplanation")}
                </span>
            </div>
            <div className="imminent-calving">
                <h1 className="imminent-calving-header">
                    {t("imminentCalving")}
                </h1>
                <span className="buttons">
                    <Button
                        onClicked={() =>
                            callConfig(
                                "imminentCalving",
                                !imminentCalvingCall,
                                imminentCalvingNotifications,
                                imminentCalvingEmail,
                            )
                        }
                        className={cn(
                            "call",
                            { deactived: !imminentCalvingCall },
                            {
                                disabled:
                                    vacationMode ||
                                    (ownerUser.apiUserTypesId === 3 &&
                                        !callPriviligesEnabled) ||
                                    (!willCalledForCustomer &&
                                        ownerUser.apiUserTypesId === 3),
                            },
                            imminentCalvingCallUi,
                        )}
                        text={t("call")}
                        type="action"
                        behaviour="instant"
                        disabled={
                            vacationMode ||
                            (ownerUser.apiUserTypesId === 3 &&
                                !callPriviligesEnabled) ||
                            (!willCalledForCustomer &&
                                ownerUser.apiUserTypesId === 3)
                        }
                    />
                    <Button
                        onClicked={() =>
                            notificationsConfig(
                                "imminentCalving",
                                imminentCalvingCall,
                                !imminentCalvingNotifications,
                                imminentCalvingEmail,
                            )
                        }
                        className={cn(
                            "notifications",
                            { deactived: !imminentCalvingNotifications },
                            { disabled: vacationMode },
                            imminentCalvingNotificationsUi,
                        )}
                        text={t("notifications")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode}
                    />
                    <Button
                        onClicked={() =>
                            emailConfig(
                                "imminentCalving",
                                imminentCalvingCall,
                                imminentCalvingNotifications,
                                !imminentCalvingEmail,
                            )
                        }
                        className={cn(
                            "email",
                            { deactived: !imminentCalvingEmail },
                            { disabled: vacationMode },
                            imminentCalvingEmailUi,
                        )}
                        text={t("email")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode}
                    />
                </span>
                <span className="imminent-calving-explanation">
                    {t("imminentCalvingExplanation")}
                </span>
            </div>
            <div className="calving-overdue">
                <h1 className="calving-overdue-header">
                    {t("calvingOverdueText")}
                </h1>
                <span className="buttons">
                    <Button
                        onClicked={() =>
                            callConfig(
                                "calvingOverdue",
                                !calvingOverdueCall,
                                calvingOverdueNotifications,
                                calvingOverdueEmail,
                            )
                        }
                        className={cn(
                            "call",
                            { deactived: !calvingOverdueCall },
                            { disabled: true },
                            calvingOverdueCallUi,
                        )}
                        text={t("call")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode || !callPriviligesEnabled}
                    />
                    <Button
                        onClicked={() =>
                            notificationsConfig(
                                "calvingOverdue",
                                calvingOverdueCall,
                                !calvingOverdueNotifications,
                                calvingOverdueEmail,
                            )
                        }
                        className={cn(
                            "notifications",
                            { deactived: !calvingOverdueNotifications },
                            { disabled: vacationMode },
                            calvingOverdueNotificationsUi,
                        )}
                        text={t("notifications")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode}
                    />
                    <Button
                        onClicked={() =>
                            emailConfig(
                                "calvingOverdue",
                                calvingOverdueCall,
                                calvingOverdueNotifications,
                                !calvingOverdueEmail,
                            )
                        }
                        className={cn(
                            "email",
                            { deactived: !calvingOverdueEmail },
                            { disabled: vacationMode },
                            calvingOverdueEmailUi,
                        )}
                        text={t("email")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode}
                    />
                </span>
                <span className="calving-overdue-explanation">
                    {t("calvingOverdueExplanation")}
                </span>
            </div>
            <div className="fever-hypothermia">
                <h1 className="fever-hypothermia-header">
                    {t("feverHypothermia")}
                </h1>
                <span className="buttons">
                    <Button
                        onClicked={() =>
                            callConfig(
                                "feverHypothermia",
                                !feverHypothermiaCall,
                                feverHypothermiaNotifications,
                                feverHypothermiaEmail,
                            )
                        }
                        className={cn(
                            "call",
                            { deactived: !feverHypothermiaCall },
                            { disabled: true },
                            feverHypothermiaCallUi,
                        )}
                        text={t("call")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode || !callPriviligesEnabled}
                    />
                    <Button
                        onClicked={() =>
                            notificationsConfig(
                                "feverHypothermia",
                                feverHypothermiaCall,
                                !feverHypothermiaNotifications,
                                feverHypothermiaEmail,
                            )
                        }
                        className={cn(
                            "notifications",
                            { deactived: !feverHypothermiaNotifications },
                            { disabled: vacationMode },
                            feverHypothermiaNotificationsUi,
                        )}
                        text={t("notifications")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode}
                    />
                    <Button
                        onClicked={() =>
                            emailConfig(
                                "feverHypothermia",
                                feverHypothermiaCall,
                                feverHypothermiaNotifications,
                                !feverHypothermiaEmail,
                            )
                        }
                        className={cn(
                            "email",
                            { deactived: !feverHypothermiaEmail },
                            { disabled: vacationMode },
                            feverHypothermiaEmailUi,
                        )}
                        text={t("email")}
                        type="action"
                        behaviour="instant"
                        disabled={vacationMode}
                    />
                </span>
                <span className="fever-hypothermia-explanation">
                    {t("feverHypothermiaExplanation")}
                </span>
            </div>
        </main>
    );
}
function mapStateToProps({
    notificationSettings,
    tags: { user },
    yourProfile: { ownerUserId },
    farms: { current },
    users,
}) {
    const calledUserTag = user[ownerUserId].find(
        (t) => t.value === USER.CALLED_USER,
    );
    return {
        ui: notificationSettings.ui,
        vacationMode: notificationSettings.vacationMode,
        calvingOverdueCall:
            notificationSettings.noticeTypes.calvingOverdue.call.status,
        calvingOverdueCallUi:
            notificationSettings.noticeTypes.calvingOverdue.call.ui,
        calvingOverdueNotifications:
            notificationSettings.noticeTypes.calvingOverdue.notification.status,
        calvingOverdueNotificationsUi:
            notificationSettings.noticeTypes.calvingOverdue.notification.status
                .ui,
        calvingOverdueEmail:
            notificationSettings.noticeTypes.calvingOverdue.email.status,
        calvingOverdueEmailUi:
            notificationSettings.noticeTypes.calvingOverdue.email.ui,
        feverHypothermiaCall:
            notificationSettings.noticeTypes.feverHypothermia.call.status,
        feverHypothermiaCallUi:
            notificationSettings.noticeTypes.feverHypothermia.call.status.ui,
        feverHypothermiaNotifications:
            notificationSettings.noticeTypes.feverHypothermia.notification
                .status,
        feverHypothermiaNotificationsUi:
            notificationSettings.noticeTypes.feverHypothermia.notification.ui,
        feverHypothermiaEmail:
            notificationSettings.noticeTypes.feverHypothermia.email.status,
        feverHypothermiaEmailUi:
            notificationSettings.noticeTypes.feverHypothermia.email.ui,
        imminentCalvingCall:
            notificationSettings.noticeTypes.imminentCalving.call.status,
        imminentCalvingCallUi:
            notificationSettings.noticeTypes.imminentCalving.call.ui,
        imminentCalvingNotifications:
            notificationSettings.noticeTypes.imminentCalving.notification
                .status,
        imminentCalvingNotificationsUi:
            notificationSettings.noticeTypes.imminentCalving.notification.ui,
        imminentCalvingEmail:
            notificationSettings.noticeTypes.imminentCalving.email.status,
        imminentCalvingEmailUi:
            notificationSettings.noticeTypes.imminentCalving.email.ui,
        calvingDueIn24HoursCall:
            notificationSettings.noticeTypes.calvingDueIn24Hours.call.status,
        calvingDueIn24HoursCallUi:
            notificationSettings.noticeTypes.calvingDueIn24Hours.call.ui,
        calvingDueIn24HoursNotifications:
            notificationSettings.noticeTypes.calvingDueIn24Hours.notification
                .status,
        calvingDueIn24HoursNotificationsUi:
            notificationSettings.noticeTypes.calvingDueIn24Hours.notification
                .ui,
        calvingDueIn24HoursEmail:
            notificationSettings.noticeTypes.calvingDueIn24Hours.email.status,
        calvingDueIn24HoursEmailUi:
            notificationSettings.noticeTypes.calvingDueIn24Hours.email.ui,
        callPriviligesEnabled: notificationSettings.callPriviligesEnabled,
        willCalledForCustomer: calledUserTag
            ? calledUserTag.params.willCalledFrom.includes(parseInt(current.id))
            : false,
        ownerUser: users[ownerUserId],
    };
}

const mapDispatchToProps = {
    callConfig,
    emailConfig,
    notificationsConfig,
    vacationModeConfig,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationSettings);
