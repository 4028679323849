import React from "react";

import i18n from "../../../i18n";
import { store } from "../../..";
import { finishTour } from "../../../store/actions";
import { cssSupports } from "../../../components/isMobile";
import { TAKE_SNAPSHOT } from "../../../store/actions/actionTypes";

import cowBoxSteps from "./cowBox";
import cowPreviewSteps from "./cowPreview";
import eventSteps from "./event";
import pairingListSteps from "./pairingList";
import navigationSteps from "./navigation";
import cowListSteps from "./cowList";

export const t = i18n.getFixedT(null, "tour");
export const smoothBehaviour = cssSupports("scroll-behavior: smooth")
    ? "smooth"
    : "auto";
const manualLanguage = i18n.language == "de" ? "en" : i18n.language;

export function delay(duration = 250) {
    return function () {
        return new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, duration);
        });
    };
}

export const buttons = {
    exit: {
        classes: "shepherd-button-secondary",
        text: t("buttons.exit"),
        action() {
            this.cancel();
            this.options.dispatch(finishTour());
        },
    },
    back: {
        classes: "shepherd-button-secondary",
        text: t("buttons.back"),
        action() {
            const step = this.getCurrentStep();
            if (step.options.revert) {
                step.options.revert(this);
            }
            this.back();
        },
    },
    next: {
        classes: "shepherd-button-primary",
        text: t("buttons.next"),
        action() {
            this.next();
        },
    },
    skip: {
        classes: "shepherd-button-secondary",
        text: t("buttons.skip"),
        type: "cancel",
        action() {
            this.cancel();
            this.options.dispatch(finishTour());
        },
    },
    start: {
        classes: "shepherd-button-primary",
        text: t("buttons.start"),
        type: "next",
    },
    restart: {
        classes: "shepherd-button-secondary",
        text: t("buttons.restart"),
        action() {
            this.complete();
            window.location.reload();
        },
    },
    finish: {
        classes: "shepherd-button-primary",
        text: t("buttons.finish"),
        action() {
            this.complete();
            this.options.dispatch(finishTour());
        },
    },
    userManual: {
        classes: "shepherd-button-secondary",
        text: t("buttons.userManual"),
        action() {
            window.open(
                `${window._env_.WICOW_WEBSITE}/manuals/${manualLanguage}.pdf`,
            );
        },
    },
};

export function advanceOnClick(
    element,
    { on = "bottom", block = "center", event = "click" } = {},
    back = true,
) {
    return {
        attachTo: { element, on },
        advanceOn: { selector: element, event },
        scrollTo: { behavior: smoothBehaviour, block },
        buttons: [
            buttons.exit,
            back ? buttons.back : disableButton(buttons.back),
        ],
        highlightClass: "can-click",
        title: t("clickTouch"),
    };
}

export function disableButton(button) {
    return { ...button, classes: button.classes.concat(" ", "disabled") };
}

/// This is a factory function that makes a namespace-specific
/// function for assigning a consistent id and translation key
/// to the tour steps. Every namespaced step file should
/// generate their own `step` function and use it to build
/// steps. Avoid assigning `id` and `text` properties to steps.
/// This function returns those in an object. Splat that object
/// into the step.
export function stepNamespace(ns) {
    if (ns) {
        return function stepWithNS(id) {
            return {
                id: `${ns}-${id}`,
                text: t(`steps.${ns}.${id}`),
            };
        };
    } else {
        return function stepWithoutNS(id) {
            return {
                id,
                text: t(`steps.${id}`),
            };
        };
    }
}

export function waitForState(predicate) {
    return {
        beforeShowPromise() {
            return new Promise((resolve, reject) => {
                const unsubscribe = store.subscribe(() => {
                    if (predicate(store.getState())) {
                        unsubscribe();
                        clearTimeout(timeout);
                        resolve();
                    }
                });
                const timeout = setTimeout(() => {
                    unsubscribe();
                    reject("timeout");
                }, 10000);
            });
        },
    };
}

export function waitForSnapshot(beforeShow = null) {
    return {
        beforeShowPromise() {
            return new Promise((resolve, reject) => {
                const unsubscribe = store.subscribe(() => {
                    if (store.getState().snapshot) {
                        unsubscribe();
                        clearTimeout(timeout);
                        if (beforeShow) {
                            beforeShow().then(() => {
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                    }
                });
                const timeout = setTimeout(() => {
                    unsubscribe();
                    reject("timeout");
                }, 10000);
                store.dispatch({ type: TAKE_SNAPSHOT });
            });
        },
    };
}

export function reCapture(step) {
    step._setupElements();
    step.tooltip.show();
}

export function checkForState(predicate) {
    return predicate(store.getState());
}

export function waitForNavigation(modal) {
    return waitForState(
        ({ navigation }) => navigation.routes.slice(-1)[0] === modal,
    );
}

const step = stepNamespace();

export const tourOptions = {
    useModalOverlay: true,
    includeStyles: false,
    defaultStepOptions: {
        modalOverlayOpeningPadding: 8,
        scrollTo: { behavior: smoothBehaviour, block: "center" },
        canClickTarget: false,
        beforeShowPromise: delay(),
        buttons: [buttons.exit, buttons.back, buttons.next],
    },
};

export function media(query) {
    return () => window.matchMedia(query).matches;
}

export const steps = [
    ...initialSteps(),
    ...cowBoxSteps(),
    ...eventSteps(),
    ...cowPreviewSteps(),
    ...pairingListSteps(),
    ...navigationSteps(),
    ...cowListSteps(),
    ...finalSteps(),
];

function initialSteps() {
    return [
        {
            ...step("welcome"),
            buttons: [buttons.skip, buttons.start],
        },
        {
            ...step("dashboard"),
            attachTo: { element: "main.dashboard section.cows" },
            scrollTo: null,
        },
    ];
}

function finalSteps() {
    return [
        {
            ...step("thank-you"),
            buttons: [
                buttons.restart,
                buttons.back,
                buttons.userManual,
                buttons.finish,
            ],
        },
    ];
}
