import React from "react";
import { connect } from "react-redux";
import cn from "classnames";

import Events from "../Events";
import NoteForm from "../NoteForm";
import Cows from "../Cows";
import NavBar from "../NavBar";
import hasLifecycle from "../../containers/Lifecycle";
import "./Dashboard.css";

function Dashboard({ dashboardSettings: { autoSortingBy }, disabled }) {
    return (
        <React.Fragment>
            <NavBar classNames={cn({ navigating: disabled })} />
            <main className={cn("dashboard", { navigating: disabled })}>
                <Cows filter="inSessionPlus48h" sort={autoSortingBy} />
                <section className="note-form">
                    <NoteForm />
                </section>
                <section className="events">
                    <Events />
                </section>
            </main>
        </React.Fragment>
    );
}
function mapStateToProps({ dashboardSettings }) {
    return { dashboardSettings };
}

export default connect(mapStateToProps)(hasLifecycle(Dashboard));
