import { take } from "redux-saga/effects";
import { START_TOUR, FINISH_TOUR } from "../actions/actionTypes";
import i18n from "../../i18n";

const demoFarmLStorage = {
    longTermToken: "LTT",
    farms: "[{\"id\":\"1\",\"name\":\"Local Farm\",\"location\":\"\",\"lastVisit\":1590060631000,\"totalCows\":2,\"trackedCows\":1}]",
    language: i18n.language,
    shortcutButton: "cowList",
    autoSortingBy: "priority",
    ownerUserId: "1"
};

export function *startTour(){
    yield take(START_TOUR);
    const backup = yield JSON.stringify(window.localStorage);
    yield window.localStorage.clear();
    yield window.localStorage.setItem("backup", backup);
    yield setAllItem(demoFarmLStorage);
    window.location.href = "/?tour=demoFarm";
}

export function *stopTour(){
    yield take(FINISH_TOUR);
    const backup = yield JSON.parse(window.localStorage.getItem("backup"));
    yield window.localStorage.clear();
    yield setAllItem(backup);
    window.location.href = "/";
}

function *setAllItem(lStorage){
    yield Object.keys(lStorage).map(key => {
        window.localStorage.setItem(key, lStorage[key]);
    });
}