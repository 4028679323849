import React, { useEffect, useContext, useRef } from "react";
import cn from "classnames";

import Age from "../Age";
import CowName from "../CowName";
import SensorLabel from "../SensorLabel";
import connect from "../../containers/CowLine";
import "./CowList.css";
import { TAG_PARAMS, COW } from "../../constants";
import i18n from "../../i18n";
import Button from "../Button";
import { Slider, Overlay, Left, Right } from "../Slider/Slider";
import { RouterContext } from "../../containers/Contexts";
import { tagParams } from "../common";

import CowOptionsMenu from "./CowOptionsMenu";

export function CowLine({
    cow,
    editCow,
    onClicked,
    selected,
    classNames,
    sliderEnabled,
    onMoved,
    autoClose,
    removeCow,
    setDialog,
    clearDialog,
    showOptionsEvent,
    setShowOptionsEvent,
    cowTypes,
    cowListEditEnabled,
}) {
    const navigate = useContext(RouterContext);
    const cowLineRef = useRef();
    useEffect(() => {
        const rootEl = document.querySelector("div#root");
        const ref = cowLineRef.current;
        rootEl.addEventListener("click", removeOptions);
        if (!sliderEnabled)
            ref.addEventListener("contextmenu", contextListener);
        return () => {
            rootEl.removeEventListener("click", removeOptions);
            if (!sliderEnabled)
                ref.removeEventListener("contextmenu", contextListener);
        };
    });

    function actionHandler(type, event, cow) {
        switch (type) {
            case "edit":
                onEditClicked(event, cow);
                navigate.forward("/cows/edit");
                setShowOptionsEvent(null);
                break;
            case "remove":
                setDialog({
                    type: "confirm",
                    confirm: () => removeCow(cow.id),
                    cancel: () => clearDialog(),
                    text: "Are you sure to remove this cow?",
                });
                setShowOptionsEvent(null);
                break;
        }
    }
    function removeOptions(event) {
        if (!event.target.closest(".cow-options-menu")) {
            setShowOptionsEvent(null);
        }
    }
    function contextListener(e) {
        e.preventDefault();
        if (!e.target.closest(".cow-options-menu")) {
            setShowOptionsEvent(e);
        }
    }

    function onEditClicked(event, selectedCow) {
        event.stopPropagation();
        // remove tags and session keys from object
        const c = Object.keys(selectedCow)
            .filter((k) => k !== "sessions")
            .reduce((acc, key) => {
                return { ...acc, [key]: selectedCow[key] };
            }, {});
        const cowBreed = Object.values(cowTypes).find(
            (t) => t.label == cow.breed,
        );
        c.breed = cowBreed;
        c.isPregnant = !!Object.values(c.tags).find(
            (t) => t.value === COW.PREGNANT,
        );
        editCow(c);
    }

    const dragRightLimit = -1 / 4.5;
    const dragLeftLimit = 1 / 8;

    const transitionRightLimit = -75 / 100;
    const transitionLeftLimit = 45 / 100;

    if (sliderEnabled) {
        return (
            <Slider
                limitLeft={window.innerWidth * transitionLeftLimit}
                limitRight={window.innerWidth * transitionRightLimit}
                dragLeftBorder={window.innerWidth * dragLeftLimit}
                dragRightBorder={window.innerWidth * dragRightLimit}>
                <Overlay
                    key="Overlay"
                    cow={cow}
                    onMoved={onMoved}
                    autoClose={autoClose}>
                    <li
                        className={cn(
                            "cow",
                            cow.tags,
                            { selected },
                            classNames,
                        )}
                        onClick={onClicked}>
                        <h1>{cow.farmNumber}</h1>
                        <h2>
                            <CowName>{cow.name}</CowName>
                        </h2>
                        <h3 className="identity">
                            {spacedIdentityNumber(cow.identityNumber)}
                        </h3>
                        <ul className="tags">
                            {cow.tags.map((tag) => (
                                <li key={tag.value} className={tag.value}>
                                    <strong>{i18n.t(tag.value)}</strong>
                                    <span>
                                        {tagParams(
                                            tag.value,
                                            tag.params[TAG_PARAMS[tag.value]],
                                        )}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </li>
                </Overlay>
                <Left key="Left">
                    <li
                        className={cn(
                            "cow-options",
                            cow.tags,
                            { selected },
                            classNames,
                        )}>
                        <Button
                            className="edit"
                            onClicked={(event) =>
                                actionHandler("edit", event, cow)
                            }
                            text={i18n.t("edit")}
                            type="action"
                            behaviour="instant"
                            privilige={cowListEditEnabled}
                        />
                        <Button
                            className="remove"
                            onClicked={(event) =>
                                actionHandler("remove", event, cow)
                            }
                            text={i18n.t("remove")}
                            type="action"
                            behaviour="instant"
                            privilige={cowListEditEnabled}
                        />
                    </li>
                </Left>
                <Right key="Right">
                    <li
                        className={cn(
                            "cow-details",
                            cow.tags,
                            { selected },
                            classNames,
                        )}>
                        <h3 className="breed-age-lactation">
                            {i18n.t(cow.breed)} - <Age time={cow.birthdate} /> -
                            {i18n.t("lactation")}
                            <strong>{cow.lactationCount}</strong>
                        </h3>
                        <ul className="sensors">
                            {cow.sessions.map(({ sensor }) => (
                                <li
                                    key={sensor.id}
                                    className={sensor.tags
                                        .map((s) => s.value)
                                        .join(" ")}>
                                    <SensorLabel {...sensor} />
                                </li>
                            ))}
                        </ul>
                    </li>
                </Right>
            </Slider>
        );
    } else {
        return (
            <React.Fragment>
                <li
                    ref={cowLineRef}
                    id={`${cow.id}`}
                    className={cn(
                        "cow",
                        cow.id,
                        cow.tags,
                        { selected },
                        classNames,
                    )}
                    onClick={onClicked}>
                    <h1>{cow.farmNumber}</h1>
                    <h2>
                        <CowName>{cow.name}</CowName>
                    </h2>
                    <h3 className="identity">
                        {spacedIdentityNumber(cow.identityNumber)}
                    </h3>
                    <h3 className="breed-age">
                        {i18n.t(cow.breed)} - <Age time={cow.birthdate} />
                    </h3>
                    <h3 className="lactation">
                        {i18n.t("lactation")} &nbsp;
                        <strong>{cow.lactationCount}</strong>
                    </h3>
                    <ul className="sensors">
                        {cow.sessions.map(({ sensor }) => (
                            <li
                                key={sensor.id}
                                className={sensor.tags
                                    .map((s) => s.value)
                                    .join(" ")}>
                                <SensorLabel {...sensor} />
                            </li>
                        ))}
                    </ul>
                    <ul className="tags">
                        {cow.tags.map((tag) => (
                            <li key={tag.value} className={tag.value}>
                                <strong>{i18n.t(tag.value)}</strong>
                                <span>
                                    {tagParams(
                                        tag.value,
                                        tag.params[TAG_PARAMS[tag.value]],
                                    )}
                                </span>
                            </li>
                        ))}
                    </ul>
                    {showOptionsEvent &&
                    showOptionsEvent.target.id == cow.id ? (
                        <CowOptionsMenu
                            cow={cow}
                            action={actionHandler}
                            rightClickEvent={showOptionsEvent}
                            setShowOptionsEvent={setShowOptionsEvent}
                            isRightClick={showOptionsEvent.button == 2}
                            isCowId={showOptionsEvent.target.id == cow.id}
                        />
                    ) : null}
                </li>
            </React.Fragment>
        );
    }
}

function spacedIdentityNumber(identityNumber) {
    return identityNumber
        .split(/(\d{3})/gi)
        .filter((str) => str !== "")
        .join(" "); // 4-per-EM space
}

export default connect(CowLine);
