import React, { useRef, useState, useContext } from "react";
import { connect } from "react-redux";
import "./FarmSettings.css";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { USER } from "../../constants/schema";
import Button from "../Button";
import enterPress from "../enterPress";
import Input from "../Input";
import { useOnClickOutside } from "../outsideClick";
import {
    inviteNewUser,
    changeFarmName,
    removeUser,
    redirectCall,
} from "../../store/actions";
import { RouterContext } from "../../containers/Contexts";
import { useMobile } from "../isMobile";

import { messages } from "./errorMessages";
import UserLine from "./UserLine";

export function FarmSettings({
    ui,
    currentFarm,
    users,
    ownerUserId,
    inviteNewUser,
    changeFarmName,
    removeUser,
    changingFarmNameStatus,
    invitingNewUserStatus,
    redirectCall,
    tags,
    canRedirectCalls,
    userAddEnabled,
    callEnabled,
}) {
    const { isMobileDevice } = useMobile();
    const char = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const { t } = useTranslation();
    const farmSettingsRef = useRef();
    const inviteBoxRef = useRef();
    const changeFarmNameBoxRef = useRef();
    const navigate = useContext(RouterContext);
    useOnClickOutside(farmSettingsRef, navigate.back);

    const [stateFarmName, setStateFarmName] = useState({
        farmName: currentFarm.name,
    });
    const [stateInvite, setStateInvite] = useState({ inviteUser: null });

    const [slidedUserId, setSlidedUserId] = useState(0);

    const [validations, setValidations] = useState({
        farmName: null,
        inviteUser: null,
    });
    function onFarmNameInputChanged(key, value) {
        setStateFarmName({ ...stateFarmName, [key]: value });
        const err = validateInputs(key, value);
        const el = document.getElementById(key);
        if (err) {
            setValidations({ ...validations, [key]: err });
            el.classList.add("validate");
        } else {
            setValidations({ ...validations, [key]: null });
            el.classList.remove("validate");
        }
    }
    function onInviteInputChanged(key, value) {
        setStateInvite({ ...stateInvite, [key]: value });
        const err = validateInputs(key, value);
        const el = document.getElementById(key);
        if (err) {
            setValidations({ ...validations, [key]: err });
            el.classList.add("validate");
        } else {
            setValidations({ ...validations, [key]: null });
            el.classList.remove("validate");
        }
    }
    function validateInputs(key, value) {
        if (userAddEnabled) return null;
        switch (key) {
            case "inviteUser":
                if (value && !char.test(value) && userAddEnabled)
                    return { text: messages[1], required: true };
                if (!userAddEnabled && value)
                    return { text: messages[2], required: true };
                return null;
            case "farmName":
                if (!value && userAddEnabled)
                    return { text: messages[3], required: true };
                if (!userAddEnabled && currentFarm.name !== value)
                    return { text: messages[4], required: true };
                return null;
            default:
                return null;
        }
    }
    function inviteUser() {
        const validations = Object.keys(stateInvite).reduce((acc, key) => {
            const err = validateInputs(key, stateInvite[key]);
            return { ...acc, [key]: err };
        }, {});
        if (
            Object.values(validations)
                .filter((v) => v && v.required)
                .every((v) => !v)
        ) {
            // submit
            inviteNewUser(stateInvite.inviteUser);
        } else {
            Object.keys(validations).map((v) => {
                const el = document.getElementById(v);
                if (validations[v]) {
                    el.classList.add("validate");
                }
                return v;
            });
            setValidations(validations);
        }
    }
    function changeFarmname() {
        const validations = Object.keys(stateFarmName).reduce((acc, key) => {
            const err = validateInputs(key, stateFarmName[key]);
            return { ...acc, [key]: err };
        }, {});
        if (
            Object.values(validations)
                .filter((v) => v && v.required)
                .every((v) => !v)
        ) {
            // submit
            changeFarmName(stateFarmName.farmName);
        } else {
            Object.keys(validations).map((v) => {
                const el = document.getElementById(v);
                if (validations[v]) {
                    el.classList.add("validate");
                }
                return v;
            });
            setValidations(validations);
        }
    }
    function onMoved(user) {
        setSlidedUserId(user.id);
    }

    function removeUserLocal(userId) {
        const sure = window.confirm("Are you sure delete this user?");
        if (sure) removeUser(userId);
    }
    const calledUser = Object.keys(tags.user).find((id) =>
        tags.user[id].map((t) => t.value).includes(USER.CALLED_USER),
    );
    let willCalledUser;
    if (!calledUser)
        willCalledUser = Object.keys(users).find((id) => users[id].isFarmAdmin);
    else willCalledUser = calledUser;
    const cantCangeFarmname = !userAddEnabled || validations.farmName;
    const cantInvite = !userAddEnabled || validations.farmName;
    return (
        <main className="farm-settings" ref={farmSettingsRef}>
            <h1 className="header">{t("farmSettings")}</h1>
            <span className="close-button" onClick={navigate.back} />
            <div className="farm-name-box" ref={changeFarmNameBoxRef}>
                <Input
                    id="farmName"
                    classNames="farm-name"
                    label={t("farmName")}
                    value={stateFarmName.farmName}
                    onChanged={(value) =>
                        onFarmNameInputChanged("farmName", value)
                    }
                    validationMessage={validations.farmName}
                    onKeyPress={enterPress(
                        changeFarmNameBoxRef,
                        () => changeFarmname(),
                        userAddEnabled,
                    )}
                />
                <Button
                    onClicked={changeFarmname}
                    className={cn("change-button", changingFarmNameStatus, {
                        disabled: cantCangeFarmname,
                    })}
                    type="action"
                    behaviour="instant"
                    disabled={cantCangeFarmname}
                />
            </div>
            <div className="users">
                <h2 className="users-header">{t("users")}</h2>
                <ul className="user-list">
                    {Object.values(users).map((user) => (
                        <UserLine
                            key={user.id}
                            user={user}
                            ownerUserId={ownerUserId}
                            removeUser={removeUserLocal}
                            farmAdmin={userAddEnabled}
                            SliderEnabled={isMobileDevice}
                            onMoved={onMoved}
                            autoClose={user.id !== slidedUserId}
                            isUserOwner={ownerUserId === user.id}
                            redirectCall={redirectCall}
                            tags={tags.user[user.id]}
                            currentFarm={currentFarm}
                            willCalled={willCalledUser === user.id}
                            canSlide={canRedirectCalls}
                            callEnabled={callEnabled}
                        />
                    ))}
                </ul>
                <div className="invite-new-user" ref={inviteBoxRef}>
                    <Input
                        id="inviteUser"
                        classNames="invite-user"
                        label={t("inviteNewUserByEmail")}
                        value={stateInvite.inviteUser}
                        onChanged={(value) =>
                            onInviteInputChanged("inviteUser", value)
                        }
                        validationMessage={validations.inviteUser}
                        onKeyPress={enterPress(
                            inviteBoxRef,
                            () => inviteUser(),
                            userAddEnabled,
                        )}
                    />
                    <Button
                        onClicked={inviteUser}
                        className={cn("invite-button", invitingNewUserStatus, {
                            disabled: cantInvite,
                        })}
                        type="action"
                        behaviour="instant"
                        disabled={cantInvite}
                    />
                </div>
            </div>
        </main>
    );
}
function mapStateToProps({
    farmSettings,
    users,
    yourProfile,
    farms,
    tags,
    priviliges,
}) {
    return {
        ui: farmSettings.ui,
        currentFarm: farms.current,
        users: Object.keys(users).reduce((acc, id) => {
            if (
                !tags.user[id] ||
                tags.user[id].map((t) => t.value).includes(USER.DELETED)
            )
                return acc;
            console.log(id, yourProfile.ownerUserId, users[id].apiUserTypesId);
            if (
                users[id].apiUserTypesId === "3" ||
                id === yourProfile.ownerUserId
            )
                return { ...acc, [id]: users[id] };
            return acc;
        }, {}),
        tags: tags,
        ownerUserId: yourProfile.ownerUserId,
        changingFarmNameStatus: farmSettings.changeFarmName,
        invitingNewUserStatus: farmSettings.inviteNewUser,
        canRedirectCalls: priviliges.CAN_REDIRECT_CALLS.value,
        userAddEnabled: priviliges.USER_ADD_ENABLED.value,
        callEnabled: priviliges.CALL_ENABLED.value,
    };
}

const mapDispatchToProps = {
    inviteNewUser,
    changeFarmName,
    removeUser,
    redirectCall,
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmSettings);
