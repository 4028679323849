import React from "react";
import { Trans } from "react-i18next";

import i18n from "../../i18n";

import "./ErrorBoundary.css";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log(error, info);
    }
    render() {
        function onErrorClicked() {
            const url = new URL(window.location);
            window.localStorage.removeItem("lastFarmId");
            window.location.href = url.origin;
        }
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <aside className="modal error">
                    <h2>{i18n.t("error")}</h2>
                    <p>
                        <Trans i18nKey="somethinghWentWrong">
                            Something went wrong. Please
                            <a onClick={onErrorClicked}>refresh</a> the wiCow
                            Dashboard.
                        </Trans>
                    </p>
                </aside>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
