export default {
    MAIN_MENU: "/mainMenu",
    PROFILE: "/profile",
    NOTIFICATION_SETTINGS: "/settings/notification",
    DASHBOARD_SETTINGS: "/settings/dashboard",
    FARM_SETTINGS: "/settings/farm",
    SWITCH_FARM: "/switchFarm",
    SENSORS: "/sensors",
    COWS: "/cows",
    ADD_COW: "/cows/add",
    EDIT_COW: "/cows/edit",
    DASHBOARD: "/",
    FARM_METRICS: "/farmMetrics",
    HEALTH_REPORT: "/healthReport",
    POTENTIAL_LIST: "/potentialList",
    POTENTIAL_INSEMINATION_LIST: "/potentialInseminationList",
};
