import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Time from "../Time";
import { sendNewSmartNote } from "../../store/actions";

import { VARIABLES } from "./constants";
import {
    TimeVariable,
    HealthComplication,
    VariableSingle,
    Variable,
} from "./common";

import "./NewSmartNote.css";

const counts = {
    one: 1,
    two: 2,
    three: 3,
};

const oneCalf = {
    healthCondition: VARIABLES.healthCondition.default,
    gender: null,
};

function ConfirmCalving({
    id,
    toogleSmartNote,
    sendNewSmartNote,
    about,
    parentEventTagItems,
    defaults,
    isResult,
}) {
    const smartNoteRef = useRef(null);
    const { t } = useTranslation();
    useEffect(() => {
        document.addEventListener("click", onClickOutside, true);
        return () => {
            document.removeEventListener("click", onClickOutside, true);
        };
    });
    function onClickOutside(event) {
        if (
            smartNoteRef.current &&
            !smartNoteRef.current.contains(event.target)
        ) {
            toogleSmartNote(false);
        }
    }
    const [state, setState] = useState(defaults);
    const nullOption =
        state.calfs.find((c) => !c.gender) || !state.calvingCondition;
    let completed = false;
    if (!nullOption) {
        completed = true;
    }
    function onCalfsSelected(v, index) {
        const calfs = [...state.calfs];
        const updatedCalf = { ...calfs[index], [v.parent]: v.value };
        calfs[index] = updatedCalf;
        setState({
            ...state,
            calfs: calfs,
        });
    }

    function setHealthComplication(option) {
        if (
            (option.id !== "noComplication" &&
                state.healthComplication.includes("noComplication")) ||
            option.id === "noComplication"
        ) {
            setState({
                ...state,
                healthComplication: state.healthComplication.includes(
                    option.value,
                )
                    ? state.healthComplication.filter((h) => h !== option.value)
                    : [option.value],
            });
            return;
        }
        if (option.id === "noComplication") {
            setState({
                ...state,
                healthComplication: state.healthComplication.includes(
                    option.value,
                )
                    ? state.healthComplication.filter((h) => h !== option.value)
                    : [option.value],
            });
            return;
        }
        setState({
            ...state,
            healthComplication: state.healthComplication.includes(option.value)
                ? state.healthComplication.filter((h) => h !== option.value)
                : [...state.healthComplication, option.value],
        });
    }
    return (
        <div className="smart-note confirm-calving" ref={smartNoteRef}>
            <div className={cn("note", { result: isResult })}>
                <CalvingTime
                    value={Time({
                        time: state.calvingTime,
                        options: {
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                        },
                    })}
                    label={`${t("smartNote:headers:calvingTime")}:`}
                    date={new Date(state.calvingTime)}
                    setTime={(value) =>
                        setState({ ...state, calvingTime: value })
                    }
                />
                <VariableSingle
                    value={state.calfCount}
                    label={`${t("smartNote:headers:calfCount")}:`}
                    options={Object.values(VARIABLES.calfCount.options)}
                    onSelected={(option) =>
                        setState({
                            ...state,
                            calfCount: option.value,
                            calfs: new Array(counts[option.value])
                                .fill(null)
                                .map((_) => oneCalf),
                        })
                    }
                    selected={!!state.calfCount}
                />
                <Calfs
                    count={counts[state.calfCount]}
                    label={`${t("smartNote:headers:calfs")}:`}
                    calfs={state.calfs}
                    onSelected={onCalfsSelected}
                />
                <VariableSingle
                    value={
                        state.calvingCondition
                            ? state.calvingCondition
                            : VARIABLES.calvingCondition.placeholder
                    }
                    label={`${t("smartNote:headers:calvingCondition")}:`}
                    selected={!!state.calvingCondition}
                    options={Object.values(VARIABLES.calvingCondition.options)}
                    onSelected={(option) =>
                        setState({
                            ...state,
                            calvingCondition: option.value,
                        })
                    }
                />
                <HealthComplication
                    value={VARIABLES.healthComplication.placeholder}
                    label={`${t("smartNote:headers:complication")}:`}
                    onSelected={(option) => setHealthComplication(option)}
                    selected={false}
                    selectedOptions={state.healthComplication}
                    isResult={isResult}
                />
            </div>
            {!isResult ? (
                <i
                    className={cn("envelope", { completed: completed })}
                    onClick={() =>
                        completed
                            ? sendNewSmartNote(
                                  about,
                                  parentEventTagItems,
                                  state,
                              )
                            : console.log("not completed")
                    }
                />
            ) : null}
        </div>
    );
}

function mapStateToProps({ events }, { parentEventTagItems, defaults }) {
    const isResult = defaults;
    if (!isResult) {
        defaults = {
            cow: parentEventTagItems.cow,
            calvingTime: events[parentEventTagItems.eventId].timestamp,
            calfCount: VARIABLES.calfCount.default,
            calfs: [
                {
                    healthCondition: VARIABLES.healthCondition.default,
                    gender: null,
                },
            ],
            calvingCondition: null,
            healthComplication: [],
        };
    }
    return { defaults, isResult };
}

const mapDispatchToProps = { sendNewSmartNote };

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCalving);

function CalvingTime({ label, value, date, setTime }) {
    return (
        <div className="variable-container">
            <h4>{label}</h4>
            <TimeVariable value={value} date={date} setTime={setTime} />
        </div>
    );
}

function Calfs({ calfs, label, onSelected }) {
    return (
        <div className="variable-container group">
            <h4>{label}</h4>
            <div className="variables">
                {calfs.map((c, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Variable
                                value={c.healthCondition}
                                options={Object.values(
                                    VARIABLES.healthCondition.options,
                                )}
                                onSelected={(o) => onSelected(o, index)}
                                selected={!!c.healthCondition}
                            />
                            <Variable
                                value={
                                    c.gender
                                        ? c.gender
                                        : VARIABLES.gender.placeholder
                                }
                                options={Object.values(
                                    VARIABLES.gender.options,
                                )}
                                onSelected={(o) => onSelected(o, index)}
                                selected={!!c.gender}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
}
