import React, { useState, useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import DataGraph from "../DataGraph";
import Time from "../Time";
import CowName from "../CowName";
import "./CowPreview.css";
import { SESSION, COW } from "../../constants/schema";
import TAG_PARAMS from "../../constants/tagParams";
import { useMobile } from "../isMobile";
import EventGroup from "../EventGroup";
import { editCow } from "../../store/actions";
import { getBrowserType, tagParams } from "../common";
import Button from "../Button";
import { RouterContext } from "../../containers/Contexts";
import i18n from "../../i18n";

import {
    CowPreviewSlider,
    Overlay,
    Left,
    Right,
} from "./CowPreviewSlider/CowPreviewSlider";
import CowActionMenu from "./CowActionMenu";
import Session from "./Session";

const birthdateOptions = {
    month: "numeric",
    year: "numeric",
};
const type = getBrowserType();

function CowPreview({
    cow,
    cowTags,
    sessions,
    eventView,
    eventLinks,
    editCow,
    cowTypes,
    cowListEditEnabled,
    setToast,
}) {
    const navigate = useContext(RouterContext);
    const cowPreviewRef = useRef();
    const [isLandscape, setIsLandscape] = useState(false);
    const [slidedCowId, setSlidedCowId] = useState(0);
    const [isSlided, setIsSlided] = useState(false);
    const [showOptionsEvent, setShowOptionsEvent] = useState(null);
    const [tooltipClickedVisible, setTooltipClickedVisible] = useState(false);

    const { isMobileDevice, isLandscapeView } = useMobile();
    useEffect(() => {
        const rootEl = document.querySelector("div#root");
        const ref = cowPreviewRef.current;
        window.addEventListener("resize", onLandscape);
        if (!isMobileDevice) {
            rootEl.addEventListener("click", removeOptions);
            ref.addEventListener("contextmenu", contextListener);
        }
        return () => {
            window.removeEventListener("resize", onLandscape);
            if (!isMobileDevice) {
                rootEl.removeEventListener("click", removeOptions);
                ref.removeEventListener("contextmenu", contextListener);
            }
        };
    });
    const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
    function onLandscape() {
        if (type === "Safari") {
            setIsLandscape(width > height);
        }
    }
    function onMoved(cow) {
        setSlidedCowId(cow.id);
    }
    function removeOptions(event) {
        if (!event.target.closest(".cow-menu")) {
            setShowOptionsEvent(null);
        }
    }
    function contextListener(e) {
        e.preventDefault();
        if (!e.target.closest(".cow-menu")) {
            setShowOptionsEvent(e);
            setTooltipClickedVisible(false);
        }
    }
    function actionHandler(event, cow) {
        if (cowListEditEnabled === false) {
            setToast(true);
            return;
        }
        onEditClicked(event, cow);
        navigate.forward("/cows/edit");
        setShowOptionsEvent(null);
    }
    function onEditClicked(event, selectedCow) {
        event.stopPropagation();
        // remove tags and session keys from object
        const c = Object.keys(selectedCow)
            .filter((k) => k !== "sessions")
            .reduce((acc, key) => {
                return { ...acc, [key]: selectedCow[key] };
            }, {});
        const cowBreed = cowTypes.find((t) => t.label === cow.breed);
        c.breed = cowBreed;
        editCow(c);
    }
    const dragRightLimit = -1 / 4.5;
    const dragLeftLimit = 1 / 8;

    return (
        <aside
            className={cn({ "cow-preview": true })}
            id={`${cow.id}`}
            ref={cowPreviewRef}
            open>
            {isMobileDevice && !isLandscapeView ? (
                <CowPreviewSlider
                    limitLeft={window.innerWidth}
                    limitRight={-window.innerWidth}
                    dragLeftBorder={window.innerWidth * dragLeftLimit}
                    dragRightBorder={window.innerWidth * dragRightLimit}>
                    <Overlay
                        key="Overlay"
                        cow={cow}
                        onMoved={onMoved}
                        autoClose={cow.id !== slidedCowId}>
                        <div className="graph">
                            <header className="main-header">
                                <h1>
                                    <strong>{cow.farmNumber}</strong>
                                    <CowName>{cow.name}</CowName>
                                </h1>
                                <h4 className="identity-number">
                                    {cow.identityNumber}
                                </h4>
                                <div className="sessions">
                                    {sessions.map((session) => (
                                        <Session
                                            type="label"
                                            session={session}
                                            key={session.id}
                                            setToast={setToast}
                                        />
                                    ))}
                                </div>
                                <div className="breed-birthdate">
                                    <h4 className="breed">
                                        {i18n.t(cow.breed)}
                                    </h4>
                                    <h4 className="birthdate">
                                        &nbsp;
                                        <Time
                                            time={cow.birthdate}
                                            options={birthdateOptions}
                                        />
                                    </h4>
                                </div>
                                <ul className="tags">
                                    {cowTags.map((tag) => (
                                        <li
                                            key={tag.value}
                                            className={tag.value}>
                                            {i18n.t(tag.value)}
                                            <span>
                                                {tagParams(
                                                    tag.value,
                                                    tag.params[
                                                        TAG_PARAMS[tag.value]
                                                    ],
                                                )}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                {/* <div className="pickers">
                                    <Pickers id={cow.id} />
                                </div> */}
                            </header>
                            <div>
                                <DataGraph
                                    id={cow.id}
                                    tooltipClickedVisible={
                                        tooltipClickedVisible
                                    }
                                    setTooltipClickedVisible={
                                        setTooltipClickedVisible
                                    }
                                />
                            </div>
                        </div>
                    </Overlay>
                    <Left key="Left">
                        <div className={cn("cow-options")} open>
                            <header>
                                <h1>
                                    <strong>{cow.farmNumber}</strong>
                                    <CowName>{cow.name}</CowName>
                                </h1>
                                <div className="sessions">
                                    {sessions.map((session) => (
                                        <Session
                                            type="label"
                                            session={session}
                                            key={session.id}
                                            setToast={setToast}
                                        />
                                    ))}
                                </div>
                                <ul className="tags">
                                    {cowTags.map((tag) => (
                                        <li
                                            key={tag.value}
                                            className={tag.value}>
                                            {i18n.t(tag.value)}
                                            <span>
                                                {tagParams(
                                                    tag.value,
                                                    tag.params[
                                                        TAG_PARAMS[tag.value]
                                                    ],
                                                )}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </header>
                            <div>
                                {sessions.map((session) => (
                                    <Session
                                        type="button"
                                        session={session}
                                        key={session.id}
                                        setToast={setToast}
                                    />
                                ))}
                                <Button
                                    onClicked={(event) =>
                                        actionHandler(event, cow)
                                    }
                                    className="edit"
                                    text={i18n.t("edit")}
                                    type="action"
                                    behaviour="instant"
                                />
                            </div>
                        </div>
                    </Left>
                    <Right key="Right" setIsSlided={setIsSlided}>
                        <div className={cn("event-group")} open>
                            {
                                <EventGroup
                                    key={cow.id}
                                    eventIds={eventView.eventIds.cow[cow.id]}
                                    eventLinks={eventLinks}
                                    isSlided={isSlided}
                                    setIsSlided={setIsSlided}
                                />
                            }
                        </div>
                    </Right>
                </CowPreviewSlider>
            ) : (
                <React.Fragment>
                    <header className="main-header">
                        <h1>
                            <strong>{cow.farmNumber}</strong>
                            <CowName>{cow.name}</CowName>
                        </h1>
                        <h4 className="identity-number">
                            {cow.identityNumber}
                        </h4>
                        <div className="sessions">
                            {sessions.map((session) => (
                                <Session
                                    type="label"
                                    session={session}
                                    key={session.id}
                                    setToast={setToast}
                                />
                            ))}
                        </div>
                        <div className="breed-birthdate">
                            <h4 className="breed">{i18n.t(cow.breed)}</h4>
                            <h4 className="birthdate">
                                &nbsp;
                                <Time
                                    time={cow.birthdate}
                                    options={birthdateOptions}
                                />
                            </h4>
                        </div>
                        <ul className="tags">
                            {cowTags.map((tag) => (
                                <li key={tag.value} className={tag.value}>
                                    {i18n.t(tag.value)}
                                    <span>
                                        {tagParams(
                                            tag.value,
                                            tag.params[TAG_PARAMS[tag.value]],
                                        )}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </header>
                    <div className="body">
                        {/* <div className="pickers">
                            <Pickers id={cow.id} />
                        </div> */}
                        <DataGraph
                            id={cow.id}
                            tooltipClickedVisible={tooltipClickedVisible}
                            setTooltipClickedVisible={setTooltipClickedVisible}
                        />
                    </div>
                    {showOptionsEvent ? (
                        <CowActionMenu
                            cow={cow}
                            action={actionHandler}
                            sessions={sessions}
                            rightClickEvent={showOptionsEvent}
                            setRightClickEvent={setShowOptionsEvent}
                            setToast={setToast}
                        />
                    ) : null}
                </React.Fragment>
            )}
        </aside>
    );
}

function mapStateToProps(
    {
        cows,
        sessions,
        tags,
        oldItems,
        eventView,
        eventLinks,
        cowTypes,
        priviliges,
    },
    { id },
) {
    const finishableCowSessions = Object.values(sessions)
        .filter(({ cow }) => cow === id)
        .filter((session) => {
            return (
                !oldItems.sessionIds.includes(session.id) &&
                tags.session[session.id][0] &&
                (tags.session[session.id]
                    .map((t) => t.value)
                    .includes(SESSION.PENDING) ||
                    tags.session[session.id]
                        .map((t) => t.value)
                        .includes(SESSION.ACTIVE))
            );
        });
    const cowBreeds = Object.values(cowTypes).reduce((acc, ct) => {
        return [...acc, { value: ct.id, label: ct.cowTypeName }];
    }, []);
    return {
        cow: cows[id],
        cowTags: tags.cow[id].filter(
            (tag) =>
                tag.value === COW.PREGNANT ||
                tag.value === COW.DUE ||
                tag.value === COW.LONG_DUE ||
                tag.value === COW.CALVING ||
                tag.value === COW.FEVER ||
                tag.value === COW.ESTROUS ||
                tag.value === COW.HEAT ||
                tag.value === COW.POST_PARTUM ||
                tag.value === COW.INSEMINATED,
        ),
        sessions: finishableCowSessions,
        eventView,
        eventLinks,
        cowTypes: cowBreeds,
        cowListEditEnabled: priviliges.COW_LIST_EDIT_ENABLED.value,
    };
}
const mapDispatchToProps = { editCow };

export default connect(mapStateToProps, mapDispatchToProps)(CowPreview);
