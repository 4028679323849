import resourceReducer from "./resource";
import lifecycleReducer from "./lifecycle";
import keyedReducer from "./keyed";

import * as ACTION from "../actions/actionTypes";

export { default as language } from "./language";
export { default as loginForm } from "./loginForm";
export { default as noteForm } from "./noteForm";
export { default as events } from "./events";
export { default as preview } from "./preview";
export { default as temperature } from "./temperature";
export { default as data } from "./data";
export { default as pairing } from "./pairing";
export const cows = resourceReducer("cows");
export const users = resourceReducer("users");
export const sensors = resourceReducer("sensors");
export const basestations = resourceReducer("basestations");
export const sessions = resourceReducer("sessions");
export { default as tags } from "./tags";
export { default as eventWindow } from "./eventWindow";
export { default as farms } from "./farms";
export { default as configs } from "./configs";
export { default as navigation } from "./navigation";
export { default as yourProfile } from "./yourProfile";
export { default as switchFarm } from "./switchFarm";
export { default as notificationSettings } from "./notificationSettings";
export { default as farmSettings } from "./farmSettings";
export { default as dashboardSettings } from "./dashboardSettings";
export { default as sensorManagement } from "./sensorManagement";
// export { default as cowList } from "./cowList";
export { default as cowListFilter } from "./cowListFilter";
export { default as smartNote } from "./smartNote";
export { default as eventLinks } from "./linkedEvents";
export { default as cowModal } from "./cowModal";
export { default as cowTypes } from "./cowTypes";
export { default as pairingLists } from "./pairingLists";
export { default as repeaterData } from "./repeaterData";
export { default as basestationData } from "./basestationData";
export { default as eventView } from "./eventView";
export { default as oldItems } from "./oldItems";
export { default as dialog } from "./dialog";
export { default as metrics } from "./metrics";
export { default as automation } from "./automation";
export { default as healthReport } from "./healthReport";
export { default as potentialList } from "./potentialList";
export { default as priviliges } from "./priviliges";
export { default as demoModal } from "./demoModal";

export const start_lifecycle = lifecycleReducer(
    ACTION.START_APP_STARTUP,
    ACTION.COMPLETE_APP_STARTUP,
    ACTION.FAIL_APP_STARTUP,
    ACTION.FAIL_LOGIN_REQUEST,
);
export const events_lifecycle = lifecycleReducer(
    ACTION.START_FETCH_EVENTS_REQUEST,
    ACTION.COMPLETE_FETCH_EVENTS_REQUEST,
    ACTION.FAIL_FETCH_EVENTS_REQUEST,
);
export const events_lifecycle_view = lifecycleReducer(
    ACTION.START_FETCH_EVENTS_VIEW_REQUEST,
    ACTION.COMPLETE_FETCH_EVENTS_VIEW_REQUEST,
    ACTION.FAIL_FETCH_EVENTS_VIEW_REQUEST,
);
const calvingConfirmationActions = [
    ACTION.START_CONFIRM_CALVING_REQUEST,
    ACTION.COMPLETE_CONFIRM_CALVING_REQUEST,
    ACTION.FAIL_CONFIRM_CALVING_REQUEST,
];
export const calving_confirmation_lifecycle = keyedReducer(
    "cow",
    lifecycleReducer(...calvingConfirmationActions),
    calvingConfirmationActions,
);
