import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import "./Dialog.css";
import { clearDialog } from "../../store/actions";

function Dialog({ type, confirm, cancel, text, clearDialog }) {
    const { t } = useTranslation();
    if (!type) return null;
    switch (type) {
        case "error":
            return (
                <aside className="error-message">
                    <h2>{t("error")}</h2>
                    <p>
                        {/* <Trans i18nKey="errorMessage">
                </Trans> */}
                        {text}
                    </p>
                    <Button
                        className={"ok"}
                        text={t("ok")}
                        type="action"
                        behaviour="instant"
                        onClicked={() => clearDialog()}
                    />
                </aside>
            );
        case "confirm":
            return (
                <aside className="confirm-message">
                    <h2>{t("confirm")}</h2>
                    <p>
                        {/* <Trans i18nKey="confirmMessage">
                    Are you sure? Do you want to remove?
                </Trans> */}
                        {text}
                    </p>
                    <Button
                        className={"cancel"}
                        text={t("no")}
                        type="action"
                        behaviour="instant"
                        onClicked={cancel}
                    />
                    <Button
                        className={"confirm"}
                        text={t("yes")}
                        type="action"
                        behaviour="instant"
                        onClicked={confirm}
                    />
                </aside>
            );
        default:
            return null;
    }
}

function mapStateToProps({ dialog }) {
    return { ...dialog };
}
const mapDispatchToProps = { clearDialog };
export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
