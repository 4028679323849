import React, { useState } from "react";
import { connect } from "react-redux";
import "./NewPairingListButton.css";
import cn from "classnames";

import Toast from "../Toast";
import { showPairingUI } from "../../store/actions";

import ActivationLoader from "./ActivationLoader";

export function NewPairingListButton({ uiState, onClick, pairingEnabled }) {
    const [toast, setToast] = useState(false);
    function onClickLocal() {
        if (!pairingEnabled) {
            setToast(true);
            return;
        }
        onClick();
    }
    if (!pairingEnabled) {
        return (
            <>
                <button
                    className={cn("new-pairing-list", {
                        disabled: !pairingEnabled,
                    })}
                    onClick={onClickLocal}
                />
                {toast ? <Toast onFinish={() => setToast(false)} /> : null}
            </>
        );
    } else if (uiState === "AVAILABLE") {
        return (
            <button className={cn("new-pairing-list")} onClick={onClickLocal} />
        );
    } else if (uiState === "UNAVAILABLE") {
        return (
            <div className="loader">
                <ActivationLoader className="new-pairing-list" />
            </div>
        );
    }
    return null;
}

function mapStateToProps(state) {
    return {
        uiState: state.pairing.ui,
        pairingEnabled: state.priviliges.PAIRING_ENABLED
            ? state.priviliges.PAIRING_ENABLED.value
            : false,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onClick() {
            dispatch(showPairingUI());
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewPairingListButton);
