import React, { useReducer, useEffect, useRef } from "react";
import "./PairingModal.css";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import PairingBox from "../PairingBox";
import { submitPairingList, hidePairingUI } from "../../store/actions";
import { useOnClickOutside } from "../outsideClick";
import { COW } from "../../constants/schema";

export function PairingModal({
    uiState,
    onSubmit,
    onCancel,
    cows,
    sensors,
    selectedCowIds,
    tags,
    events,
    external,
    pregTrackingTime,
    estrousEnabled,
    pregnancyEnabled,
}) {
    const { t } = useTranslation();
    const sensorPairingRef = useRef();
    const [pairs, dispatch] = useReducer(reducer, []);
    let pairNotExist = true;
    if (pairs.filter(({ cow, sensor }) => cow && sensor).length > 0) {
        pairNotExist = false;
    }
    useEffect(() => {
        dispatch({
            action: "update-cow-tags",
            tags,
            estrousEnabled,
            pregnancyEnabled,
        });
    }, [estrousEnabled, events, pregnancyEnabled, tags]);

    useEffect(() => {
        const pairCows = pairs
            .filter((pair) => pair.cow)
            .map((pair) => pair.cow.id);
        selectedCowIds
            .map((id) => {
                return {
                    ...cows[id],
                    tagValues: tags.cow[id].map((t) => t.value),
                };
            })
            .filter((cow) => cow && !pairCows.includes(cow.id))
            .map((cow) =>
                dispatch({
                    action: "insert-cow",
                    cow,
                    tags,
                    pregTrackingTime,
                    estrousEnabled,
                    pregnancyEnabled,
                }),
            );
    }, [
        cows,
        pregTrackingTime,
        pairs,
        selectedCowIds,
        tags,
        estrousEnabled,
        pregnancyEnabled,
    ]);

    useEffect(() => {
        const pairCows = pairs
            .filter((pair) => pair.cow)
            .map((pair) => pair.cow.id);
        external
            .sort((a, b) => a.sensor - b.sensor)
            .filter((p) => p.cow && !pairCows.includes(p.cow))
            .map(({ cow, sensor }) =>
                dispatch({
                    action: "insert-pair",
                    cow: cows[cow],
                    sensor: sensors[sensor],
                    tags,
                    pregTrackingTime,
                    estrousEnabled,
                    pregnancyEnabled,
                }),
            );
    }, [
        cows,
        pregTrackingTime,
        external,
        pairs,
        sensors,
        tags,
        estrousEnabled,
        pregnancyEnabled,
    ]);

    useOnClickOutside(
        sensorPairingRef,
        uiState === "VISIBLE" ? () => onCancel() : () => null,
    );

    function submitPairs() {
        const unfinishedExist = pairs.find(
            ({ cow, sensor }) => !(cow && sensor),
        );
        if (unfinishedExist) {
            if (window.confirm(t("unfinishedPairsExistDoYouWantToProceed"))) {
                onSubmit(
                    pairs
                        .filter(({ cow, sensor }) => cow && sensor)
                        .map((pair) => {
                            dispatch({
                                action: "drop-pair",
                                pair,
                                pregTrackingTime,
                                estrousEnabled,
                                pregnancyEnabled,
                            });
                            return {
                                cow: pair.cow.id,
                                sensor: pair.sensor.id,
                                tracking: pair.tracking,
                            };
                        }),
                );
            }
        } else {
            onSubmit(
                pairs
                    .filter(({ cow, sensor }) => cow && sensor)
                    .map((pair) => {
                        dispatch({
                            action: "drop-pair",
                            pair,
                            pregTrackingTime,
                            estrousEnabled,
                            pregnancyEnabled,
                        });
                        return {
                            cow: pair.cow.id,
                            sensor: pair.sensor.id,
                            tracking: pair.tracking,
                        };
                    }),
            );
        }
    }

    function pasteHandler(event) {
        const clip = event.clipboardData.getData("text/plain");
        if (clip.match(/^\s*\d+(?:[;,\s]+\d+)+\s*$/)) {
            event.preventDefault();
            event.target.blur();
            const farmNumbers = clip.match(/\d+/g);
            const cowsArray = Object.values(cows);
            farmNumbers
                .map((number) => {
                    const cow = cowsArray.find(
                        (cow) => cow.farmNumber === number,
                    );
                    if (cow) {
                        cow._search = number;
                    }
                    return cow;
                })
                .filter((cow) => cow)
                .map((cow) =>
                    dispatch({
                        action: "insert-cow",
                        cow,
                        tags,
                        pregTrackingTime,
                        estrousEnabled,
                        pregnancyEnabled,
                    }),
                );
        }
    }

    const selectedSensors = pairs.filter((p) => p.sensor).map((p) => p.sensor);
    switch (uiState) {
        case "VISIBLE":
            return (
                <aside
                    className="pairing"
                    onPaste={pasteHandler}
                    ref={sensorPairingRef}>
                    <header>
                        <h1>{t("tsensPairing")}</h1>
                    </header>
                    <div className="pairing-boxes">
                        {pairs.map((pair, index) => {
                            return (
                                <PairingBox
                                    key={JSON.stringify(pair)}
                                    onCow={(cow) =>
                                        dispatch({
                                            action: "update-cow",
                                            cow,
                                            index,
                                            tags,
                                            pregTrackingTime,
                                            estrousEnabled,
                                            pregnancyEnabled,
                                        })
                                    }
                                    onSensor={(sensor) =>
                                        dispatch({
                                            action: "update-sensor",
                                            sensor,
                                            index,
                                            tags,
                                            estrousEnabled,
                                            pregnancyEnabled,
                                        })
                                    }
                                    onTracking={(trackingType) =>
                                        dispatch({
                                            action: "update-tracking",
                                            trackingType,
                                            index,
                                            tags,
                                            estrousEnabled,
                                            pregnancyEnabled,
                                        })
                                    }
                                    trackingTypes={pair.tracking}
                                    {...pair}
                                    selectedSensors={selectedSensors}
                                    focused={pair.focused}
                                    requiredInfo={pair.required}
                                />
                            );
                        })}
                        {pairs.length === 0 ||
                        pairs[pairs.length - 1].cow ||
                        pairs[pairs.length - 1].sensor ? (
                            <PairingBox
                                key={pairs.length}
                                animate={pairs.length > 0}
                                onCow={(cow) =>
                                    dispatch({
                                        action: "insert-cow",
                                        cow,
                                        tags,
                                        pregTrackingTime,
                                        estrousEnabled,
                                        pregnancyEnabled,
                                    })
                                }
                                onSensor={(sensor) =>
                                    dispatch({
                                        action: "insert-sensor",
                                        sensor,
                                        tags,
                                        estrousEnabled,
                                        pregnancyEnabled,
                                    })
                                }
                                onTracking={(trackingType) =>
                                    dispatch({
                                        action: "insert-tracking",
                                        trackingType,
                                        tags,
                                        estrousEnabled,
                                        pregnancyEnabled,
                                    })
                                }
                                trackingTypes={[]}
                                selectedSensors={selectedSensors}
                            />
                        ) : null}
                    </div>
                    <footer>
                        <button className="cancel" onClick={onCancel}>
                            {t("notNow")}
                        </button>
                        <button
                            disabled={pairNotExist}
                            className={cn("submit", { disabled: pairNotExist })}
                            onClick={submitPairs}>
                            {t("submit")}
                        </button>
                    </footer>
                </aside>
            );
        default:
            return null;
    }
}

function mapStateToProps({
    pairing,
    cows,
    sensors,
    tags,
    events,
    configs,
    priviliges,
}) {
    return {
        uiState: pairing.ui,
        cows,
        sensors,
        selectedCowIds: pairing.cowsFromCowList,
        tags,
        events,
        external: pairing.external,
        pregTrackingTime: configs.find(
            (c) => c.name === "PREGNANCY_TRACKING_TIME",
        ),
        estrousEnabled: priviliges.ESTROUS_DETECTION_ENABLED
            ? priviliges.ESTROUS_DETECTION_ENABLED.value
            : false,
        pregnancyEnabled: priviliges.PREGNANCY_DETECTION_ENABLED
            ? priviliges.PREGNANCY_DETECTION_ENABLED.value
            : false,
    };
}

const mapDispatchToProps = {
    onSubmit: submitPairingList,
    onCancel: hidePairingUI,
};

export default connect(mapStateToProps, mapDispatchToProps)(PairingModal);

function reducer(
    pairs,
    {
        action,
        cow,
        sensor,
        trackingType,
        index,
        pair,
        tags,
        pregTrackingTime,
        estrousEnabled,
        pregnancyEnabled,
    },
) {
    switch (action) {
        case "update-cow":
            pairs[index] = {
                ...pairs[index],
                cow,
                tracking: availableTrackings(
                    pairs[index].tracking,
                    cow,
                    tags,
                    pairs[index].sensor,
                    pregTrackingTime,
                    estrousEnabled,
                    pregnancyEnabled,
                ),
                focused: cow ? null : "cow",
                required: null,
            };
            return [...pairs];
        case "update-sensor":
            pairs[index] = {
                ...pairs[index],
                tracking: availableTrackings(
                    pairs[index].tracking,
                    pairs[index].cow,
                    tags,
                    sensor,
                    pregTrackingTime,
                    estrousEnabled,
                    pregnancyEnabled,
                ),
                sensor,
                focused: sensor ? null : "sensor",
            };
            return [...pairs];
        case "update-tracking":
            const tagValues = tags.cow[pairs[index].cow.id].map((t) => t.value);
            if (pairs[index].tracking.includes(trackingType)) {
                pairs[index].tracking = pairs[index].tracking.filter(
                    (t) => t !== trackingType,
                );
                if (
                    trackingType === "pregnancy" &&
                    tagValues.includes(COW.PREGNANT)
                ) {
                    pairs[index].required = "lastCalvingDate";
                } else if (
                    trackingType === "estrous" &&
                    tagValues.includes(COW.ESTROUS)
                ) {
                    pairs[index].required = "lastBreedingDate";
                } else {
                    pairs[index].required = null;
                    if (
                        tagValues.includes(COW.ESTROUS) &&
                        !pairs[index].tracking.includes("estrous")
                    ) {
                        pairs[index].tracking.push("estrous");
                        pairs[index].tracking = pairs[index].tracking.filter(
                            (t) => t !== "pregnancy",
                        );
                    }
                    if (
                        tagValues.includes(COW.PREGNANT) &&
                        !pairs[index].tracking.includes("pregnancy")
                    ) {
                        pairs[index].tracking.push("pregnancy");
                        pairs[index].tracking = pairs[index].tracking.filter(
                            (t) => t !== "estrous",
                        );
                    }
                }
            } else {
                if (trackingType === "pregnancy") {
                    if (!tagValues.includes(COW.PREGNANT)) {
                        pairs[index].required = "lastBreedingDate";
                        pairs[index].tracking = [
                            ...pairs[index].tracking,
                            trackingType,
                        ];
                    } else {
                        pairs[index].required = null;
                        pairs[index].tracking = [
                            ...pairs[index].tracking,
                            trackingType,
                        ];
                    }
                    pairs[index].tracking = pairs[index].tracking.filter(
                        (t) => t !== "estrous",
                    );
                } else if (trackingType === "estrous") {
                    if (
                        tagValues.includes(COW.PREGNANT) &&
                        pairs[index].required
                    ) {
                        pairs[index].required = null;
                        pairs[index].tracking.push("pregnancy");
                    } else {
                        if (
                            !tagValues.includes(COW.ESTROUS) &&
                            !tagValues.includes(COW.POST_PARTUM)
                        ) {
                            pairs[index].required = "lastCalvingDate";
                        } else {
                            pairs[index].required = null;
                        }
                        pairs[index].tracking = pairs[index].tracking.filter(
                            (t) => t !== "pregnancy",
                        );
                        pairs[index].tracking = [
                            ...pairs[index].tracking,
                            trackingType,
                        ];
                    }
                } else if (trackingType === "health") {
                    pairs[index].tracking = [
                        ...pairs[index].tracking,
                        trackingType,
                    ];
                }
            }
            return [...pairs];
        case "insert-cow":
            return [
                ...pairs,
                {
                    cow,
                    tracking: availableTrackings(
                        [],
                        cow,
                        tags,
                        sensor,
                        pregTrackingTime,
                        estrousEnabled,
                        pregnancyEnabled,
                    ),
                },
            ];
        case "insert-pair":
            return [
                ...pairs,
                {
                    cow,
                    sensor,
                    tracking: availableTrackings(
                        [],
                        cow,
                        tags,
                        sensor,
                        pregTrackingTime,
                        estrousEnabled,
                        pregnancyEnabled,
                    ),
                },
            ];
        case "insert-sensor":
            return [
                ...pairs,
                {
                    sensor,
                    tracking: availableTrackings(
                        [],
                        cow,
                        tags,
                        sensor,
                        pregTrackingTime,
                        estrousEnabled,
                        pregnancyEnabled,
                    ),
                },
            ];
        case "insert-tracking":
            return [...pairs, { tracking: [trackingType] }];
        case "drop-pair":
            return pairs.filter((p) => p !== pair);
        case "update-cow-tags":
            return pairs.map((p) => {
                if (!p.cow) return p;
                const cowTagValues = tags.cow[p.cow.id].map((t) => t.value);
                let required;
                if (p.required === "lastBreedingDate") {
                    required = cowTagValues.includes(COW.PREGNANT)
                        ? null
                        : p.required;
                } else {
                    required = cowTagValues.includes(COW.PREGNANT)
                        ? p.required
                        : null;
                }
                return {
                    ...p,
                    cow: {
                        ...p.cow,
                        tagValues: cowTagValues,
                    },
                    required,
                };
            });
        default:
            return pairs;
    }
}

function availableTrackings(
    initial,
    cow,
    tags,
    sensor,
    pregTrackingTime,
    estrousEnabled,
    pregnancyEnabled,
) {
    const avTrackings = initial;
    if (!cow) return [];

    if (sensor && sensor.type === "earTag") {
        return ["health"];
    }
    const tagValues = tags.cow[cow.id].map((t) => t.value);
    if (
        pregnancyEnabled &&
        tagValues.includes(COW.PREGNANT) &&
        !avTrackings.includes("pregnancy")
    ) {
        if (pregTrackingTime) {
            const pregDaysPassed =
                (new Date().getTime() - cow.lastBreedingDate) / 86400000; // nth days for pregnancy
            if (pregDaysPassed > parseInt(pregTrackingTime.value)) {
                avTrackings.push("pregnancy");
            }
        }
    }

    if (
        estrousEnabled &&
        tagValues.includes(COW.ESTROUS) &&
        !avTrackings.includes("estrous")
    )
        avTrackings.push("estrous");
    if (!avTrackings.includes("health")) avTrackings.push("health");
    return avTrackings;
}
