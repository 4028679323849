import React, { useRef } from "react";
import GoogleLogin from "react-google-login";
import cn from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import "./LoginForm.css";
import { loginForm as connect } from "../../containers";

export function LoginForm({
    email,
    inputEmail,
    submitEmail,
    tryAnotherLoginEmail,
    isStateRetry,
    disabled,
    googleLogin,
}) {
    const recaptchaRef = useRef();
    const googleLoginRef = useRef();
    const { t } = useTranslation();

    function change(domEvent) {
        domEvent.preventDefault();
        inputEmail(domEvent.target.value);
    }
    function submit(domEvent) {
        domEvent.preventDefault();
        submitEmail();
    }
    function tryAnotherEmail(domEvent) {
        domEvent.preventDefault();
        tryAnotherLoginEmail();
    }
    function onFailGoogle(response) {
        console.log(response);
    }
    function onSuccessGoogle(response) {
        googleLogin(response.tokenId);
    }

    const onGoogleSubmit = () => {
        recaptchaRef.current.execute();
    };

    const onReCAPTCHAChange = (captchaCode, onClick) => {
        console.log(captchaCode);
        if (!captchaCode) {
            return;
        }
        onClick();
        recaptchaRef.current.reset();
    };
    return isStateRetry ? (
        <div className="login" id="login-form">
            <Button
                className={"try-another-email"}
                text={t("tryAnotherEmail")}
                type="action"
                behaviour="instant"
                onClicked={tryAnotherEmail}
                disabled={disabled}
            />
            <Button
                className={"resend-email"}
                text={disabled ? null : t("resendEmail")}
                type="action"
                behaviour="instant"
                onClicked={submit}
                disabled={disabled}
            />
        </div>
    ) : (
        <>
            <form className="login" onSubmit={submit} id="login-form">
                <input
                    type="email"
                    onChange={change}
                    required
                    disabled={disabled}
                    value={email}
                />
                <input type="submit" disabled={disabled} />
            </form>
            <div className="google-login">
                <p>{t("or")}</p>
                <GoogleLogin
                    ref={googleLoginRef}
                    render={(renderProps) => (
                        <>
                            <button
                                type="submit"
                                className={cn("google", {
                                    disabled: renderProps.disabled,
                                })}
                                onClick={() =>
                                    onGoogleSubmit(renderProps.onClick)
                                }
                                disabled={renderProps.disabled}>
                                <span className="icon" />
                                <span className="text"> {t("login")}</span>
                            </button>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6Lc2c38cAAAAAK2qxbbTvZDKSVDBsVGgEBfdMtz9"
                                onChange={(captchaCode) =>
                                    onReCAPTCHAChange(
                                        captchaCode,
                                        renderProps.onClick,
                                    )
                                }
                            />
                        </>
                    )}
                    clientId="11761976198-b3dr41e5jg4e5a8bq3fj9huu4pr87856.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={onSuccessGoogle}
                    onFailure={onFailGoogle}
                    cookiePolicy={"single_host_origin"}
                />
            </div>
        </>
    );
}

export default connect(LoginForm);
