import * as ACTION from "./actionTypes";

export function submitNote() {
    return { type: ACTION.SUBMIT_NOTE };
}
export function inputNote(input) {
    return { type: ACTION.INPUT_NOTE, input };
}
export function togglePreview(kind, id) {
    return { type: ACTION.TOGGLE_PREVIEW, kind, id };
}
export function submitLoginEmail() {
    return { type: ACTION.SUBMIT_LOGIN_EMAIL };
}
export function tryAnotherLoginEmail() {
    return { type: ACTION.TRY_ANOTHER_LOGIN_EMAIL };
}
export function inputLoginEmail(email) {
    return { type: ACTION.INPUT_LOGIN_EMAIL, email };
}
export function logout() {
    return { type: ACTION.LOGOUT };
}
export function finishSession(id) {
    return { type: ACTION.FINISH_SESSION, id };
}
export function fetchOlderEvents() {
    return { type: ACTION.FETCH_OLDER_EVENTS };
}
export function confirmCalving(cow) {
    return { type: ACTION.CONFIRM_CALVING, cow };
}

//Smart Note
export function setVariables(variables) {
    return { type: ACTION.SET_VARIABLES, variables };
}
export function setOptions(options) {
    return { type: ACTION.SET_OPTIONS, options };
}
export function sendSmartNote(about, parentEventTagItems) {
    return { type: ACTION.SEND_SMART_NOTE, about, parentEventTagItems };
}
export function setFullText(text) {
    return { type: ACTION.SET_FULL_TEXT, text };
}
export function saveVariableOptions(variableOptions) {
    return { type: ACTION.SAVE_VARIABLE_OPTIONS, variableOptions };
}
export function setSmartNoteAbout(about) {
    return { type: ACTION.SET_SMARTNOTE_ABOUT, about };
}

// Cow Filter
export function setSearchText(text) {
    return { type: ACTION.SET_SEARCH_TEXT, text };
}

// Langıage
export function setLanguageRequest(lang) {
    return {
        type: ACTION.SET_LANGUAGE_REQUEST,
        lang,
    };
}

// Cow Modal
export function addNewCow() {
    return { type: ACTION.ADD_NEW_COW };
}
export function addCow(cowProperties, isPregnant) {
    return { type: ACTION.ADD_COW, cowProperties, isPregnant };
}
export function editCow(cow) {
    return { type: ACTION.EDIT_COW, cow };
}
export function submitEditCow(cowProperties, isPregnant) {
    return { type: ACTION.SUBMIT_EDIT_COW, cowProperties, isPregnant };
}
export function removeCow(cowId, userId) {
    return { type: ACTION.REMOVE_COW, cowId, userId };
}

// Your Profile
export function activeUser(id) {
    return { type: ACTION.SET_ACTIVE_USER, id };
}
export function updateInformation(user) {
    return { type: ACTION.UPDATE_INFORMATION, user };
}
export function callConfig(noticeEventType, call, notification, email) {
    return {
        type: ACTION.CHANGED_CALL_CONFIG,
        noticeEventType,
        call,
        notification,
        email,
    };
}
export function registerUser(usersId, name, surname, email, phoneNumber) {
    return {
        type: ACTION.REGISTER_USER,
        usersId,
        name,
        surname,
        email,
        phoneNumber,
    };
}
// Notification Settings
export function notificationsConfig(
    noticeEventType,
    call,
    notification,
    email,
) {
    return {
        type: ACTION.CHANGED_NOTIFICATIONS_CONFIG,
        noticeEventType,
        call,
        notification,
        email,
    };
}
export function emailConfig(noticeEventType, call, notification, email) {
    return {
        type: ACTION.CHANGED_EMAIL_CONFIG,
        noticeEventType,
        call,
        notification,
        email,
    };
}
export function vacationModeConfig() {
    return { type: ACTION.CHANGED_VACATION_MODE_CONFIG };
}
//Farm settings
export function removeUser(userId) {
    return { type: ACTION.REMOVE_USER, userId };
}
export function inviteNewUser(email) {
    return { type: ACTION.INVITE_NEW_USER, email };
}
export function changeFarmName(name) {
    return { type: ACTION.CHANGED_FARM_NAME, name };
}
export function changeUserCallConfig(userId, activate) {
    return { type: ACTION.CHANGED_USER_CALL_CONFIG, userId, activate };
}

export function redirectCall(userId) {
    return { type: ACTION.REDIRECT_CALL, userId };
}
//Dashboard settings
export function setShortcutButton(button) {
    return { type: ACTION.SET_SHORTCUT_BUTTON, button };
}
export function setAutoSorting(button) {
    return { type: ACTION.SET_AUTO_SORTING, button };
}

//Dialog
export function setDialog(dialog) {
    return { type: ACTION.SET_DIALOG, dialog };
}

export function clearDialog() {
    return { type: ACTION.CLEAR_DIALOG };
}

//Navigation
export function navigateForward(next) {
    return { type: ACTION.NAVIGATE_FORWARD, to: next };
}
export function forceNavigateTo(to) {
    return { type: ACTION.FORCE_NAVIGATE_TO, to };
}
export function navigateBack() {
    return { type: ACTION.NAVIGATE_BACK };
}

// Tour
export function startTour() {
    return { type: ACTION.START_TOUR };
}
export function finishTour() {
    return { type: ACTION.FINISH_TOUR };
}

export function setMetricRange(start, end) {
    return { type: ACTION.SET_METRIC_RANGE, start, end };
}

//Automation
export function turnSwitch(switchOnOff, id, deviceId) {
    return { type: ACTION.TURN_SWITCH, switchOnOff, id, deviceId };
}

export function setHealthRange(start, end) {
    return { type: ACTION.SET_HEALTH_RANGE, start, end };
}

export function togglePreviewAutomation(kind, id) {
    return { type: ACTION.TOGGLE_PREVIEW_AUTOMATION, kind, id };
}

export function sendNewSmartNote(about, parentEventTagItems, pack) {
    return {
        type: ACTION.SEND_NEW_SMART_NOTE,
        about,
        parentEventTagItems,
        pack,
    };
}

export function setPotentialList(eventId, listType) {
    return { type: ACTION.SET_POTENTIAL_LIST, eventId, listType };
}
export function oldCowDataRequest(start, end) {
    return { type: ACTION.OLD_COW_DATA_REQUEST, start, end };
}

export function finishDemo() {
    return { type: ACTION.FINISH_DEMO };
}

export function showDemoModal() {
    return { type: ACTION.SHOW_DEMO_MODAL };
}

export function hideDemoModal() {
    return { type: ACTION.HIDE_DEMO_MODAL };
}

export function registerDemoAccount(name, surname, email, phone) {
    return { type: ACTION.REGISTER_DEMO_ACCOUNT, name, surname, email, phone };
}

export function googleLogin(googleTokenId) {
    return { type: ACTION.GOOGLE_LOGIN, googleTokenId };
}

export function failLoginLinkRequest(error) {
    // @TODO
    // return { type: ACTION.FAIL, googleTokenId };
}

export * from "./cowList";
export * from "./pairing";
