/* eslint-disable react/display-name */
import React, { useCallback, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { useTransition, animated } from "react-spring";

import Dashboard from "../components/Dashboard";
import LoginModal from "../components/LoginModal";
import PairingModal from "../components/PairingModal";
import CowListView from "../components/CowListView";
import YourProfile from "../components/YourProfile";
import MainMenu from "../components/NavBar/MainMenu";
import CowModal from "../components/CowModal";
import NetworkError from "../components/NetworkError";
import ErrorBoundary from "../components/ErrorBoundary";
import NotificationSettings from "../components/NotificationSettings";
import DashboardSettings from "../components/DashboardSettings";
import SwitchFarm from "../components/SwitchFarm";
import SensorManagement from "../components/SensorManagement";
import FarmSettings from "../components/FarmSettings";
import Dialog from "../components/Dialog";
import FarmMetrics from "../components/FarmMetrics";
import HealthReport from "../components/HealthReport";
import PotentialList from "../components/PotentialList";
import { NAVIGATE_BACK, NAVIGATE_FORWARD } from "../store/actions/actionTypes";
import ROUTES from "../constants/routes";

import { RouterContext } from "./Contexts";

const modals = {
    [ROUTES.MAIN_MENU]: () => <MainMenu />,
    [ROUTES.PROFILE]: () => <YourProfile />,
    [ROUTES.NOTIFICATION_SETTINGS]: () => <NotificationSettings />,
    [ROUTES.DASHBOARD_SETTINGS]: () => <DashboardSettings />,
    [ROUTES.FARM_SETTINGS]: () => <FarmSettings />,
    [ROUTES.SWITCH_FARM]: () => <SwitchFarm />,
    [ROUTES.SENSORS]: () => <SensorManagement />,
    [ROUTES.COWS]: () => <CowListView />,
    [ROUTES.ADD_COW]: () => <CowModal />,
    [ROUTES.EDIT_COW]: () => <CowModal />,
    [ROUTES.DASHBOARD]: () => <React.Fragment />,
    [ROUTES.FARM_METRICS]: () => <FarmMetrics />,
    [ROUTES.HEALTH_REPORT]: () => <HealthReport />,
    [ROUTES.POTENTIAL_LIST]: () => <PotentialList />,
};
modals.displayName = "modals";

const App = ({ routes, config, lifecycle, tour, ShepherdTour }) => {
    const [rs, navigate] = useNavigation(routes);
    if (tour) {
        if (!ShepherdTour.isActive()) ShepherdTour.start();
    }

    const transitions = useTransition(rs, (route) => route, config);

    return (
        <ErrorBoundary>
            <RouterContext.Provider value={navigate}>
                <Dashboard lifecycle={lifecycle} disabled={rs.length > 1} />
                <LoginModal />
                <PairingModal />
                <NetworkError />
                <Dialog />
                {transitions.map(({ item: route, props, key }) => {
                    if (route == ROUTES.DASHBOARD) return;
                    const Modal = modals[route];
                    return (
                        <animated.div key={key} style={props}>
                            <Modal />
                        </animated.div>
                    );
                })}
            </RouterContext.Provider>
        </ErrorBoundary>
    );
};

function mapsStateToProps({ start_lifecycle, navigation: { routes, config } }) {
    return {
        routes,
        config,
        lifecycle: start_lifecycle,
    };
}
export default connect(mapsStateToProps)(App);

function useNavigation(routes) {
    const dispatch = useDispatch();
    const navigate = {};
    navigate.forward = useCallback(
        (next) => dispatch({ type: NAVIGATE_FORWARD, to: next }),
        [dispatch],
    );
    navigate.back = useCallback(() => dispatch({ type: NAVIGATE_BACK }), [
        dispatch,
    ]);
    return [routes, navigate];
}
